import React from "react";
// import "normalize.css";
import { debounce } from "throttle-debounce";
import { Icon, Dropdown, Menu } from "antd";
import Select from "react-select";
// import makeAnimated from "react-select/lib/animated";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import { Portal } from 'react-overlays'
import Button from "./Button";
import Textarea from "react-textarea-autosize";
import {
  DatePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
// import TextFieldStandard from "@material-ui/core/TextField";
// import Select from "@material-ui/core/Select";
// import MenuItem from "@material-ui/core/MenuItem";
// import FormControl from '@material-ui/core/FormControl';
// import InputLabel from '@material-ui/core/InputLabel';
// import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
// import Menu from '@material-ui/core/Menu';
// import DownArrowIcon from "@material-ui/icons/KeyboardArrowDown";
// import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import MenuMUI from "@material-ui/core/Menu";
import MenuItemMUI from "@material-ui/core/MenuItem";
// import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import TextBar from "./textBar";
import moment from "moment";
// const TextField = withStyles({
//   root: {
//     "& .MuiOutlinedInput-root": {
//       "& fieldset": {
//         borderColor: "#444",
//       },
//     },
//     "& .MuiFormLabel-root": {
//       color: "#444",
//     },
//   },
// })(TextFieldStandard);

const DateTimePickerStyled = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#444",
      },
    },
    "& .MuiFormLabel-root": {
      color: "#444",
    },
  },
})(DateTimePicker);

const DatePickerStyled = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#444",
      },
    },
    "& .MuiFormLabel-root": {
      color: "#444",
    },
  },
})(DatePicker);

class Field extends React.PureComponent {
  render() {
    return null;
  }
}

const customStyles = {
  menuPortal: (style) => {
    style.zIndex = 999;
    return {
      ...style,
    };
  },
  option: (style, state) => {
    style.background = state.isFocused ? "#00000022" : "#ffffffee";
    return {
      ...style,
    };
  },
};

export class CaserField extends React.PureComponent {
  checkEditable() {
    if (this.props.readonly || this.props.disabled) return false;
    return true;
  }
  sendDebounceUpdate(val, node) {
    debounce(500, (val) => {
      this.props.onChange(val, node);
    });
  }
  sendDirectUpdate(val, node) {
    this.props.onChange(val, node);
  }
}

export class CaserTextField extends CaserField {
  state = { value: this.props.value, newVal: null, focused: false };

  async onBlur() {
    await this.props.onChange(this.state.value);
    if (this.state.newVal !== null) {
      this.setState({ value: this.state.newVal, newVal: null, focused: false });
    } else if (this.state.focused) {
      this.setState({ focused: false });
    }
  }
  onFocus() {
    if (!this.state.focused) {
      this.setState({ focused: true });
    }
  }
  componentDidUpdate() {
    this.updateValue();
  }
  updateValue() {
    if (this.state.focused === true) {
      if (this.state.newVal === this.props.value) {
        return;
      }
      this.setState({ newVal: this.props.value });
    } else {
      if (this.state.value === this.props.value) {
        return;
      }
      this.setState({ value: this.props.value });
    }
  }
  onChange(event) {
    if (!this.checkEditable()) return;
    let value = event.target.value;
    // let value = h2p.fromString(event.target.value,{
    //       wordwrap: 130,
    //       preserveNewlines:true,
    //       singleNewLineParagraphs:true
    // });
    // console.log(value)
    this.setState({
      value,
    });
    if (this.props.noDebounce) this.sendDirectUpdate(value);
    else this.sendDebounceUpdate(value)
  }
  render() {
    return (
      <div className={"caser-input-container"}>
        <div className={"caser-input-label"}>{this.props.label}</div>
        <input
          type={"text"}
          className={"caser-input"}
          onFocus={this.onFocus.bind(this)}
          onBlur={this.onBlur.bind(this)}
          placeholder={this.props.label}
          value={this.state.value || ""}
          onChange={this.onChange.bind(this)}
          readOnly={this.props.readonly || false}
          disabled={this.props.disabled}
        ></input>
      </div>
    );
  }
}
export class CaserSearchField extends CaserField {
  state = { value: this.props.value, newVal: null, focused: false };
  sendUpdate = debounce(500, (val) => {
    this.props.onChange(val);
  });

  async onBlur() {
    await this.props.onChange(this.state.value);
    if (this.state.newVal !== null) {
      this.setState({ value: this.state.newVal, newVal: null, focused: false });
    } else if (this.state.focused) {
      this.setState({ focused: false });
    }
  }
  onFocus() {
    if (!this.state.focused) {
      this.setState({ focused: true });
    }
  }
  componentDidUpdate() {
    this.updateValue();
  }
  updateValue() {
    if (this.state.focused === true) {
      if (this.state.newVal === this.props.value) {
        return;
      }
      this.setState({ newVal: this.props.value });
    } else {
      if (this.state.value === this.props.value) {
        return;
      }
      this.setState({ value: this.props.value });
    }
  }
  onChange(event) {
    if (!this.checkEditable()) return;
    let value = event.target.value;
    // let value = h2p.fromString(event.target.value,{
    //       wordwrap: 130,
    //       preserveNewlines:true,
    //       singleNewLineParagraphs:true
    // });
    // console.log(value)
    this.setState({
      value,
    });
    this.sendUpdate(value);
  }
  render() {
    return (
      //   <TextField
      //     label={this.props.label}
      //     value={this.state.value || ""}
      //     onChange={this.onChange.bind(this)}
      //     margin="dense"
      //     variant="outlined"
      //     InputProps={{ readOnly: this.props.readonly }}
      //     disabled={this.props.disabled}
      //     fullWidth
      //     multiline={this.props.multiline || false}
      //     onFocus={this.onFocus.bind(this)}
      //     onBlur={this.onBlur.bind(this)}
      //   />
      <div className={"caser-input-container"}>
        {this.props.noLabel ? null : (
          <div className={"caser-input-label"}>{this.props.label}</div>
        )}
        <input
          type={"text"}
          className={"caser-input"}
          onFocus={this.onFocus.bind(this)}
          onBlur={this.onBlur.bind(this)}
          placeholder={this.props.label}
          value={this.state.value || ""}
          onChange={this.onChange.bind(this)}
          readOnly={this.props.readonly || false}
          disabled={this.props.disabled}
          autoFocus={this.props.autoFocus}
          onKeyPress={this.props.onKeyPress}
        ></input>
      </div>
    );
  }
}
export class CaserLongTextField extends CaserTextField {
  render() {
    return (
      <div className={"caser-input-container"}>
        <div className={"caser-input-label"}>{this.props.label}</div>
        <Textarea
          type={"text"}
          className={"caser-input"}
          style={{ resize: "none" }}
          onFocus={this.onFocus.bind(this)}
          onBlur={this.onBlur.bind(this)}
          placeholder={this.props.label}
          value={this.state.value || ""}
          onChange={this.onChange.bind(this)}
          readOnly={this.props.readonly || false}
          disabled={this.props.disabled}
        />
      </div>
    );
  }
}
export class CaserNumberField extends CaserField {
  state = { value: this.props.value, newVal: null, focused: false };
  sendUpdate = debounce(500, (val) => {
    this.props.onChange(val);
  });

  async onBlur() {
    await this.props.onChange(this.state.value);
    if (this.state.newVal !== null) {
      this.setState({ value: this.state.newVal, newVal: null, focused: false });
    } else if (this.state.focused) {
      this.setState({ focused: false });
    }
  }
  onFocus() {
    if (!this.state.focused) {
      this.setState({ focused: true });
    }
  }
  componentDidUpdate() {
    this.updateValue();
  }
  updateValue() {
    if (this.state.focused === true) {
      if (this.state.newVal === this.props.value) {
        return;
      }
      this.setState({ newVal: this.props.value });
    } else {
      if (this.state.value === this.props.value) {
        return;
      }
      this.setState({ value: this.props.value });
    }
  }
  onChange(event) {
    if (!this.checkEditable()) return;
    let value = event.target.value;
    // let value = h2p.fromString(event.target.value,{
    //       wordwrap: 130,
    //       preserveNewlines:true,
    //       singleNewLineParagraphs:true
    // });
    // console.log(value)
    this.setState({
      value,
    });
    this.sendUpdate(value);
  }
  render() {
    return (
      <div className={"caser-input-container"}>
        <div className={"caser-input-label"}>{this.props.label}</div>
        <input
          type={"text"}
          className={"caser-input"}
          onFocus={this.onFocus.bind(this)}
          onBlur={this.onBlur.bind(this)}
          placeholder={this.props.label}
          value={this.state.value || ""}
          onChange={this.onChange.bind(this)}
          readOnly={this.props.readonly}
          disabled={this.props.disabled}
        ></input>
      </div>
    );
  }
}
export class CaserCurrencyField extends CaserField {
  state = { value: this.props.value, newVal: null, focused: false };
  sendUpdate = debounce(500, (val) => {
    this.props.onChange(val);
  });

  async onBlur() {
    await this.props.onChange(this.state.value);
    if (this.state.newVal !== null) {
      this.setState({ value: this.state.newVal, newVal: null, focused: false });
    } else if (this.state.focused) {
      this.setState({ focused: false });
    }
  }
  onFocus() {
    if (!this.state.focused) {
      this.setState({ focused: true });
    }
  }
  componentDidUpdate() {
    this.updateValue();
  }
  updateValue() {
    if (this.state.focused === true) {
      if (this.state.newVal === this.props.value) {
        return;
      }
      this.setState({ newVal: this.props.value });
    } else {
      if (this.state.value === this.props.value) {
        return;
      }
      this.setState({ value: this.props.value });
    }
  }
  onChange(event) {
    if (!this.checkEditable()) return;
    let value = event.target.value;
    // let value = h2p.fromString(event.target.value,{
    //       wordwrap: 130,
    //       preserveNewlines:true,
    //       singleNewLineParagraphs:true
    // });
    // console.log(value)
    this.setState({
      value,
    });
    this.sendUpdate(value);
  }
  render() {
    return (
      //   <TextField
      //     label={this.props.label}
      //     value={this.state.value || ""}
      //     onChange={this.onChange.bind(this)}
      //     margin="dense"
      //     variant="outlined"
      //     InputProps={{ readOnly: this.props.readonly }}
      //     disabled={this.props.disabled}
      //     fullWidth
      //     multiline={this.props.multiline || false}
      //     onFocus={this.onFocus.bind(this)}
      //     onBlur={this.onBlur.bind(this)}
      //   />

      <div className={"caser-input-container"}>
        <div className={"caser-input-label"}>{this.props.label}</div>
        <input
          type="number"
          min="0.00"
          step="0.01"
          pattern="^\d*(\.\d{0,2})?$"
          className={"caser-input"}
          onFocus={this.onFocus.bind(this)}
          onBlur={this.onBlur.bind(this)}
          placeholder={"00.00"}
          value={this.state.value || ""}
          onChange={this.onChange.bind(this)}
          readOnly={this.props.readonly || false}
          disabled={this.props.disabled}
        ></input>
      </div>
    );
  }

  // state = { value: this.props.value, newVal: null, focused: false };
  // sendUpdate = (val) => {
  //   this.props.onChange(val);
  // };

  // async onBlur() {
  //   await this.props.onChange(this.state.value);
  //   if (this.state.newVal !== null) {
  //     this.setState({ value: this.state.newVal, newVal: null, focused: false });
  //   } else if (this.state.focused) {
  //     this.setState({ focused: false });
  //   }
  // }
  // onFocus() {
  //   if (!this.state.focused) {
  //     this.setState({ focused: true });
  //   }
  // }
  // componentDidUpdate() {
  //   this.updateValue();
  // }
  // updateValue() {
  //   if (this.state.focused === true) {
  //     if (this.state.newVal === this.props.value) {
  //       return;
  //     }
  //     this.setState({ newVal: this.props.value });
  //   } else {
  //     if (this.state.value === this.props.value) {
  //       return;
  //     }
  //     this.setState({ value: this.props.value });
  //   }
  // }
  // onChange(event) {
  //   let value = event.target.value;
  //   if (!this.checkEditable()) return;
  //   this.setState({
  //     value,
  //   });
  //   this.sendUpdate(value);
  // }
  // render() {
  //   console.log(this.state.value);
  //   return (
  //     <div className={"caser-input-container"}>
  //       <div className={"caser-input-label"}>{this.props.label}</div>
  //       <div className={"caser-field-Group"}>
  //         <div style={{ flexGrow: 0 }} className={"caser-input-container"}>
  //           <input
  //             className={"caser-input"}
  //             type={"text"}
  //             placeholder={"First"}
  //             value={"$"}
  //             readOnly
  //             style={{ width: "30px" }}
  //           />
  //         </div>
  //         <div style={{ flexGrow: 1 }} className={"caser-input-container"}>
  //           <input
  //             placeholder={"00.00"}
  //             value={this.state.value || ""}
  //             onChange={this.onChange.bind(this)}
  //             type="number"
  //             min="0.00"
  //             step="0.01"
  //             pattern="^\d*(\.\d{0,2})?$"
  //             className={"caser-input caser-input-currency"}
  //             readOnly={this.props.readonly}
  //             style={{ width: "100%" }}
  //             //   onFocus={this.onFocus.bind(this)}
  //             //   onBlur={this.onBlur.bind(this)}
  //           />
  //         </div>
  //       </div>
  //       {/* <CurrencyTextField
  //       label={this.props.label}
  //       value={this.state.value || null}
  //       onChange={this.onChange.bind(this)}
  //       margin="dense"
  //       variant="outlined"
  //       disabled={this.props.disabled}
  //       fullWidth
  //       currencySymbol="$"
  //       multiline={this.props.multiline || false}
  //       onFocus={this.onFocus.bind(this)}
  //       onBlur={this.onBlur.bind(this)}
  //       InputProps={{
  //         readOnly: this.props.readonly,
  //       }}
  //     /> */}
  //     </div>
  //   );
  // }
}

export class CaserPhoneField extends CaserField {
  state = {
    value: this.format(this.props.value),
    newVal: null,
    focused: false,
    type: this.props.typeValue,
    anchorEl: false,
    recentCallsArray: [],
  };
  onBlur() {
    if (this.state.newVal !== null) {
      this.setState({ value: this.state.newVal, newVal: null, focused: false });
    } else if (this.state.focused) {
      this.setState({ focused: false });
    }
  }
  onFocus() {
    if (!this.state.focused) {
      this.setState({ focused: true });
    }
  }
  copyToClipboard(text) {
    var textField = document.createElement("textarea");
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
  }

  componentDidMount() {
    this.updateValue();
  }
  componentDidUpdate() {
    this.updateValue();
  }
  updateValue() {
    var newVal = this.format(this.state.newVal);
    var propsVal = this.format(this.props.value);
    var stateVal = this.format(this.state.value);
    // console.log(this.state.focused);
    if (this.state.focused === true) {
      if (newVal === propsVal) {
        return;
      }
      this.setState({ newVal: propsVal });
    } else {
      if (stateVal === propsVal) {
        return;
      }
      this.setState({ value: propsVal });
    }
    if (this.props.typeValue !== this.state.type) {
      this.setState({ type: this.props.typeValue });
    }
  }
  onChange(event) {
    if (!this.checkEditable()) return;
    this.props.onChange(this.parse(event.target.value), "value");
    this.setState({ value: this.format(event.target.value) });
  }
  onTypeChange = (value) => {
    if (!this.checkEditable()) return;
    console.log(value.value);
    this.props.onChange(value.value, "type");
    this.setState({ type: value.value });
  };

  format(value) {
    // console.log("format",value)
    if (typeof value !== "string") {
      return value;
    }
    value = value.replace(/\D/g, "");
    if (value.length < 4) {
    } else if (value.length < 8) {
      value = value.substring(0, 3) + "-" + value.substring(3);
    } else if (value.length < 11) {
      value =
        "(" +
        value.substring(0, 3) +
        ") " +
        value.substring(3, 6) +
        "-" +
        value.substring(6);
    } else if (value.length < 12) {
      value =
        value.substring(0, 1) +
        " (" +
        value.substring(1, 4) +
        ") " +
        value.substring(4, 7) +
        "-" +
        value.substring(7);
    }
    return value;
  }
  formatForProduction(value) {
    // console.log("format",value)
    if (typeof value !== "string") {
      return value;
    }
    value = value.replace(/\D/g, "");
    if (value.length < 4) {
    } else if (value.length < 8) {
      value = value.substring(0, 3) + "-" + value.substring(3);
    } else if (value.length < 11) {
      value =
        "1 (" +
        value.substring(0, 3) +
        ") " +
        value.substring(3, 6) +
        "-" +
        value.substring(6);
    } else if (value.length < 12) {
      value =
        value.substring(0, 1) +
        " (" +
        value.substring(1, 4) +
        ") " +
        value.substring(4, 7) +
        "-" +
        value.substring(7);
    }
    return value;
  }
  parse(value) {
    if (typeof value !== "string") {
      return value;
    }
    value = value.replace(/\D/g, "");
    return value;
  }
  onFunctionClick = (key) => {
    // console.log(key);
    if (key === "Broward-Handset") {
      this.placeCall("handset", "Broward", this.state.value);
    } else if (key === "Palm Beach-Handset") {
      this.placeCall("handset", "Palm Beach", this.state.value);
    } else if (key === "CopyToClipboard") {
      this.copyToClipboard(this.formatForProduction(this.state.value));
    } else {
      window.open("tel:+" + this.formatForProduction(this.state.value));
    }
  };
  placeCall(device, from, to) {
    to = to.replace(/\D/g, "");
    // this.context.callFunctionSync("placeCall", { device, from, to });
  }
  handleMenuClick = () => {
    this.setState({ anchorEl: this.state.anchorEl ? false : true });
    this.updateSuggestedNumbers();
  };
  updateSuggestedNumbers = async () => {
    // var recentCallsArray = await this.context.dataGet("recentUnknowNumbers");
    // this.setState({ recentCallsArray });
  };

  menuClose = () => {
    this.setState({ anchorEl: false });
  };

  render() {
    let numberOptions = [
      { value: "Cell", label: "Cell" },
      { value: "Home", label: "Home" },
      { value: "Office", label: "Office" },
      { value: "Main", label: "Main" },
      { value: "Relative", label: "Relative" },
      { value: "Other", label: "Other" },
    ];

    return (
      //   <TextField
      //     label={this.props.label}
      //     value={this.state.value || ""}
      //     onChange={this.onChange.bind(this)}
      //     margin="dense"
      //     variant="outlined"
      //     disabled={this.props.disabled}
      //     fullWidth
      //     multiline={this.props.multiline || false}
      //     onFocus={this.onFocus.bind(this)}
      //     onBlur={this.onBlur.bind(this)}
      //     type={"tel"}
      //     InputProps={{
      //       readOnly: this.props.readonly,
      //       startAdornment: (
      //         <InputAdornment position="start">{this.state.type}</InputAdornment>
      //       ),
      //       endAdornment: (
      //         <InputAdornment position="end">
      //           <Dropdown
      //             overlay={
      //               <Menu>
      //                 <Menu.ItemGroup title="Number Actions">
      //                   <Menu.Item
      //                     key="Broward-Handset"
      //                     onClick={() => this.onFunctionClick("Broward-Handset")}
      //                   >
      //                     Broward-Handset
      //                   </Menu.Item>
      //                   <Menu.Item
      //                     key="Palm Beach-Handset"
      //                     onClick={() =>
      //                       this.onFunctionClick("Palm Beach-Handset")
      //                     }
      //                   >
      //                     Palm Beach-Handset
      //                   </Menu.Item>
      //                   <Menu.Item
      //                     key="Broward-Cell"
      //                     onClick={() => this.onFunctionClick("Broward-Cell")}
      //                   >
      //                     Direct Call
      //                   </Menu.Item>
      //                   <Menu.Item
      //                     key="CopyToClipboard"
      //                     onClick={() => this.onFunctionClick("CopyToClipboard")}
      //                   >
      //                     Copy to Clipboard
      //                   </Menu.Item>
      //                   {this.state.type === "Cell" ||
      //                   this.state.type === "Other" ? (
      //                     <Menu.Item
      //                       key="Message"
      //                       onClick={() => this.onFunctionClick("Message")}
      //                     >
      //                       <Icon type="message" /> SMS Message
      //                     </Menu.Item>
      //                   ) : null}
      //                 </Menu.ItemGroup>
      //                 <Menu.ItemGroup title="Number Type">
      //                   {numberOptions.map((v) => (
      //                     <Menu.Item
      //                       key={v.value}
      //                       onClick={() => this.onTypeChange(v.value)}
      //                     >
      //                       {v.label}
      //                     </Menu.Item>
      //                   ))}
      //                 </Menu.ItemGroup>
      //                 {this.state.recentCallsArray.length ? (
      //                   <Menu.ItemGroup title="Assign to Recently Called">
      //                     {this.state.recentCallsArray.map((v, i) => (
      //                       <Menu.Item
      //                         key={"g" + i}
      //                         onClick={() =>
      //                           this.onChange({ target: { value: v.number } })
      //                         }
      //                       >
      //                         {this.format(v.number)} ({v.dt})
      //                       </Menu.Item>
      //                     ))}
      //                   </Menu.ItemGroup>
      //                 ) : null}
      //               </Menu>
      //             }
      //             trigger={["click"]}
      //           >
      //             <IconButton
      //               aria-label="Toggle password visibility"
      //               onClick={this.handleMenuClick}
      //               onClose={this.menuClose}
      //             >
      //               <MoreHorizIcon fontSize="small" />
      //             </IconButton>
      //           </Dropdown>
      //         </InputAdornment>
      //       ),
      //     }}
      //   />

      <div className={"caser-input-container caser-input-phone-container"}>
        <div className={"caser-input-label"}>
          <TextBar
           
          >
            {this.props.label}
          </TextBar>
        </div>
        <div className={"caser-field-Group"}>          
          <div style={{ flexGrow: 7 }} className={"caser-input-container"}>
            <input
              inputmode="tel"
              placeholder={this.props.label}
              value={this.state.value}
              type={"tel"}
              className={"caser-input"}
              onChange={this.onChange.bind(this)}
              readOnly={this.props.readonly}
              onFocus={this.onFocus.bind(this)}
              onBlur={this.onBlur.bind(this)}
              style={{ maxHeight: "38px" }}
            />
          </div>
        </div>
      </div>
    );
  }
}
export class CaserSelectField extends CaserField {
  // state = { value: this.props.value }
  // componentDidUpdate() {
  //     this.updateValue();
  // }
  // updateValue() {
  //     // console.log("Updating");
  //     // console.log(this.state.value);
  //     // console.log(this.props.value);
  //     if (this.state.value === this.props.value) {
  //         return;
  //     }
  //     // console.log("Updating...comensing");

  //     this.setState({ value: this.props.value });
  // }
  onChange({ value }) {
    if (!this.checkEditable()) return;
    this.props.onChange(value);
    // this.setState({ value })
  }
  render() {
    // console.log("render");
    var options = (this.props.opts || []).map((option) => {
      return { value: option.optionId, label: option.label };
    });
    let optionText = options.reduce((r, v) => {
      r[v.value] = v.label;
      return r;
    }, {});
    // console.log(options, options.filter((v, i, a) => this.props.value === v.value), this.props.value);
    return (
      <div className={"caser-input-container"} style={this.props.style}>
        <div className={"caser-input-label"}>{this.props.label}</div>
        {this.props.readOnly ? (
          <input
            type={"text"}
            className={"caser-input"}
            value={optionText[this.state.value] || ""}
            readOnly={this.props.readonly || false}
            disabled={this.props.disabled}
            autoFocus={this.props.autoFocus}
            onKeyPress={this.props.onKeyPress}
          ></input>
        ) : (
          <Select
            menuIsOpen={this.checkEditable() ? undefined : false}
            className={
              "caser-input-select" + (this.props.readonly ? " readonly" : "")
            }
            classNamePrefix={"caser-input-select"}
            placeholder={this.props.label}
            defaultValue={options.filter(
              (v, i, a) => this.props.value === v.value
            )}
            value={options.filter((v, i, a) => this.props.value === v.value)}
            onChange={this.onChange.bind(this)}
            readOnly={this.props.readonly}
            disabled={this.props.disabled}
            options={options}
            menuPortalTarget={window.document.getElementById("root")}
            isSearchable={this.props.search ? true : false}
            styles={customStyles}
            menuPlacement={"auto"}
          />
        )}
      </div>
    );
  }
}
export class CaserRadioField extends CaserField {
  onChange(value) {
    if (!this.checkEditable()) return;
    if (value === this.props.value) this.props.onChange(null);
    else this.props.onChange(value);
  }
  render() {
    return (
      <div className={"caser-input-container"} style={this.props.style}>
        <div className={"caser-input-label"}>{this.props.label}</div>
        <div type={"text"} className={"caser-input caser-radio-input"}>
          {(this.props.opts || []).map((v, i) => (
            <div
              key={i}
              onClick={() => this.onChange(v.optionId)}
              className={
                "caser-radio-element" +
                (v.optionId === this.props.value ? " selected" : "")
              }
            >
              {v.label}
            </div>
          ))}
        </div>
      </div>
    );
  }
}
export class CaserNameField extends CaserField {
  suffixOptions = [
    { value: "", label: "" },
    { value: "Jr", label: "Junior" },
    { value: "Sr", label: "Senior" },
    { value: "Third", label: "Third" },
    { value: "Fourth", label: "Fourth" },
    { value: "Fifth", label: "Fifth" },
  ];
  onChange(event, node) {
    if (!this.checkEditable()) return;
    this.sendDirectUpdate(event.target.value, node);
  }
  onFirstChange(event) {
    this.onChange(event, "firstname");
  }
  onMiddleChange(event) {
    this.onChange(event, "middlename");
  }
  onLastChange(event) {
    this.onChange(event, "lastname");
  }

  render() {
    return (
      <div>
        {this.props.noLabel ? null : (
          <div className={"caser-input-label"}>{this.props.label}</div>
        )}
        <div className={"caser-field-Group"}>
          <div style={{ flexGrow: 3 }} className={"caser-input-container"}>
            <input
              placeholder={"First"}
              value={this.props.value.dbObj.firstname || ""}
              onChange={this.onFirstChange.bind(this)}
              type={"text"}
              className={"caser-input"}
              readOnly={this.props.readonly}
              style={{ width: "100%" }}
            />
            <div className={"caser-input-label"}>First Name</div>
          </div>
          <div style={{ flexGrow: 3 }} className={"caser-input-container"}>
            <input
              placeholder={"Middle"}
              value={this.props.value.dbObj.middlename || ""}
              onChange={this.onMiddleChange.bind(this)}
              type={"text"}
              className={"caser-input"}
              readOnly={this.props.readonly}
              style={{ width: "100%" }}
            />{" "}
            <div className={"caser-input-label"}>Middle Name</div>
          </div>
          <div style={{ flexGrow: 3 }} className={"caser-input-container"}>
            <input
              placeholder={"Last"}
              value={this.props.value.dbObj.lastname || ""}
              onChange={this.onLastChange.bind(this)}
              type={"text"}
              className={"caser-input"}
              readOnly={this.props.readonly}
              style={{ width: "100%" }}
            />
            <div className={"caser-input-label"}>Last Name</div>
          </div>
        </div>
        {/* <div
            style={{ width: "10%", flexGrow: 1 }}
            className={"caser-input-container"}
          >
            <Select
              components={makeAnimated()}
              className={"caser-input-select"}
              classNamePrefix={"caser-input-select"}
              placeholder={"Type"}
              defaultValue={numberOptions.filter(
                (v, i, a) => this.state.type === v.value
              )}
              value={numberOptions.filter(
                (v, i, a) => this.state.type === v.value
              )}
              onChange={this.onTypeChange.bind(this)}
              InputProps={{ readOnly: this.props.readonly }}
              isDisabled={this.props.disabled}
              options={numberOptions}
              menuPortalTarget={window.document.getElementById("root")}
              styles={customStyles}
              isSearchable={false}
              menuPlacement={"auto"}
            />
          </div>
        </div> */}
        {/* <Grid container spacing={2}>
          <Grid item md={4} xs={12}>
            <TextField
              id="outlined-name"
              label="First Name"
              placeholder={"First"}
              value={this.props.value.dbObj.firstname || ""}
              onChange={this.onFirstChange.bind(this)}
              InputProps={{ readOnly: this.props.readonly }}
              margin="dense"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              id="outlined-name"
              label="Middle Name"
              placeholder={"Middle"}
              value={this.props.value.dbObj.middlename || ""}
              onChange={this.onMiddleChange.bind(this)}
              InputProps={{ readOnly: this.props.readonly }}
              margin="dense"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              id="outlined-name"
              label="Last Name"
              placeholder={"Last"}
              value={this.props.value.dbObj.lastname || ""}
              onChange={this.onLastChange.bind(this)}
              InputProps={{ readOnly: this.props.readonly }}
              margin="dense"
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid> */}
      </div>
    );
  }
}

export class CaserDateTimeField extends CaserField {
  render() {
    return (
      <div className={"caser-input-container"}>
        <div className={"caser-input-label"}>{this.props.label}</div>
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
          <DateTimePickerStyled
            value={this.props.value}
            minutesStep={5}
            onChange={this.props.onChange}
            margin="dense"
            readOnly={this.props.readonly}
            disabled={this.props.disabled}
            fullWidth
            TextFieldComponent={(props) => {
              console.log(props);
              return (
                <input
                  placeholder={this.props.label}
                  type={"text"}
                  className={"caser-input"}
                  value={props.value}
                  onClick={props.onClick}
                  onKeyDown={props.onKeyDown}
                  readOnly
                />
              );
            }}
            format={"MMM D, YYYY h:mm a"}
          />
        </MuiPickersUtilsProvider>
      </div>
    );
  }
}

export class CaserWeekField extends CaserField {
  render() {
    return (
      //   <MuiPickersUtilsProvider utils={MomentUtils}>
      //     <DateTimePickerStyled
      //       label={this.props.label}
      //       inputVariant="outlined"
      //       value={this.props.value}
      //       minutesStep={5}
      //       onChange={this.props.onChange}
      //       margin="dense"
      //       InputProps={{ readOnly: this.props.readonly }}
      //       disabled={this.props.disabled}
      //       fullWidth
      //       format={"MMM D, YYYY h:mm a"}
      //     />
      //   </MuiPickersUtilsProvider>

      <div className={"caser-input-container"}>
        {this.props.noLabel ? null : (
          <div className={"caser-input-label"}>{this.props.label}</div>
        )}
        <div className={"caser-field-Group"}>
          <div
            style={{ flexGrow: 0 }}
            className={"caser-input-container"}
          ></div>
          <div style={{ flexGrow: 1 }} className={"caser-input-container"}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DateTimePickerStyled
                // label={this.props.label}
                // inputVariant="outlined"
                value={this.props.value}
                minutesStep={5}
                onChange={this.props.onChange}
                margin="dense"
                readOnly={this.props.readonly}
                disabled={this.props.disabled}
                fullWidth
                TextFieldComponent={(props) => {
                  console.log(props);
                  return (
                    <input
                      placeholder={this.props.label}
                      type={"text"}
                      className={"caser-input"}
                      value={props.value}
                      onClick={props.onClick}
                      onKeyDown={props.onKeyDown}
                      readOnly
                    />
                  );
                }}
                format={"MMM D, YYYY h:mm a"}
              />
            </MuiPickersUtilsProvider>
          </div>
          <div
            style={{ flexGrow: 0 }}
            className={"caser-input-container"}
          ></div>
        </div>
        {/* <DatePicker
                                                      InputProps={{                                 readOnly: this.props.readonly,                               }}
                          style={{ width: "100%" }}
                          showTimeSelect={this.isTime}
                          timeIntervals={15}
                          dateFormat={this.isTime ? "MMMM d, yyyy h:mm aa" : "MMMM d, yyyy"}
                          timeCaption="Time"
                          popperContainer={CalendarContainer}
                          popperPlacement={"auto"}
                          // className={"caser-input"}
                          customInput={<CustomContainerInput />}
                      /> */}
      </div>
    );
  }
}

// class CustomContainerInput extends React.Component {

//     render() {
//         return (
//             <button
//                 className="caser-input"
//                 onClick={this.props.onClick}>
//                 {this.props.value}
//             </button>
//         )
//     }
// }

// const CalendarContainer = ({ children }) => {
//     const el = document.getElementById('root')

//     return (
//         <Portal container={el}>
//             {children}
//         </Portal>
//     )
// }

export class CaserDateField extends CaserField {
  render() {
    return (
      //   <MuiPickersUtilsProvider utils={MomentUtils}>
      //     <DatePickerStyled
      //       autoOk
      //       label={this.props.label}
      //       value={this.props.value}
      //       onChange={this.props.onChange.bind(this)}
      //       format="MMM D, YYYY"
      //       inputVariant="outlined"
      //       margin="dense"
      //       InputProps={{ readOnly: this.props.readonly }}
      //       disabled={this.props.disabled}
      //       fullWidth
      //     />
      //   </MuiPickersUtilsProvider>
      <div className={"caser-input-container"}>
        <div className={"caser-input-label"}>{this.props.label}</div>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DatePickerStyled
            // label={this.props.label}
            // inputVariant="outlined"
            value={this.props.value}
            autoOk
            onChange={this.props.onChange}
            readOnly={this.props.readonly}
            disabled={this.props.disabled}
            fullWidth
            TextFieldComponent={(props) => {
              console.log(props);
              return (
                <input
                  placeholder={this.props.label}
                  type={"text"}
                  className={"caser-input"}
                  value={props.value}
                  onClick={props.onClick}
                  onKeyDown={props.onKeyDown}
                  readOnly
                />
              );
            }}
            format="MMM D, YYYY"
          />
        </MuiPickersUtilsProvider>
      </div>
    );
  }
}
export class CaserAddressField extends CaserField {
  render() {
    // console.log(this.props.value)
    return (
      <div>
        <div className={"caser-input-label"}>{this.props.label}</div>
        <div className={"caser-field-Group"}>
          <div style={{ flexGrow: 2 }} className={"caser-input-container"}>
            <input
              placeholder={"Street #"}
              value={this.props.value.dbObj.streetNumber || ""}
              type={"text"}
              className={"caser-input"}
              readOnly={this.props.readonly}
              style={{ width: "100%" }}
              //   onFocus={this.onFocus.bind(this)}
              //   onBlur={this.onBlur.bind(this)}
            />
            <div className={"caser-input-label"}>Street #</div>
          </div>
          <div style={{ flexGrow: 3 }} className={"caser-input-container"}>
            <input
              placeholder={"Street"}
              value={this.props.value.dbObj.streetName || ""}
              type={"text"}
              className={"caser-input"}
              readOnly={this.props.readonly}
              style={{ width: "100%" }}
              //   onFocus={this.onFocus.bind(this)}
              //   onBlur={this.onBlur.bind(this)}
            />
            <div className={"caser-input-label"}>Street</div>
          </div>
          <div style={{ flexGrow: 3 }} className={"caser-input-container"}>
            <input
              placeholder={"Street 2"}
              value={this.props.value.dbObj.street2 || ""}
              type={"text"}
              className={"caser-input"}
              readOnly={this.props.readonly}
              style={{ width: "100%" }}
              //   onFocus={this.onFocus.bind(this)}
              //   onBlur={this.onBlur.bind(this)}
            />
            <div className={"caser-input-label"}>Street 2</div>
          </div>
        </div>
        <div className={"caser-field-Group"}>
          <div style={{ flexGrow: 5 }} className={"caser-input-container"}>
            <input
              placeholder={"City"}
              value={this.props.value.dbObj.city || ""}
              type={"text"}
              className={"caser-input"}
              readOnly={this.props.readonly}
              style={{ width: "100%" }}
              //   onFocus={this.onFocus.bind(this)}
              //   onBlur={this.onBlur.bind(this)}
            />
            <div className={"caser-input-label"}>City</div>
          </div>
          <div style={{ flexGrow: 2 }} className={"caser-input-container"}>
            <input
              placeholder={"State"}
              value={this.props.value.dbObj.state || ""}
              type={"text"}
              className={"caser-input"}
              readOnly={this.props.readonly}
              style={{ width: "100%" }}
              //   onFocus={this.onFocus.bind(this)}
              //   onBlur={this.onBlur.bind(this)}
            />
            <div className={"caser-input-label"}>State</div>
          </div>
          <div style={{ flexGrow: 2 }} className={"caser-input-container"}>
            <input
              placeholder={"Zip Code"}
              value={this.props.value.dbObj.zip || ""}
              type={"text"}
              className={"caser-input"}
              readOnly={this.props.readonly}
              style={{ width: "100%" }}
              //   onFocus={this.onFocus.bind(this)}
              //   onBlur={this.onBlur.bind(this)}
            />
            <div className={"caser-input-label"}>Zip Code</div>
          </div>
        </div>
        {/* <Grid container spacing={2}>
          <Grid item md={4} xs={12}>
            <TextField
              id="outlined-name"
              label="Street #"
              placeholder={"Street #"}
              value={this.props.value.dbObj.streetNumber || ""}
              type={"text"}
              onChange={this.props.onChange}
              InputProps={{ readOnly: this.props.readonly }}
              margin="dense"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              id="outlined-name"
              label="Street"
              placeholder={"Street"}
              value={this.props.value.dbObj.streetName || ""}
              type={"text"}
              onChange={this.props.onChange}
              InputProps={{ readOnly: this.props.readonly }}
              margin="dense"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              id="outlined-name"
              label="Street 2"
              placeholder={"Street 2"}
              value={this.props.value.dbObj.street2 || ""}
              type={"text"}
              onChange={this.props.onChange}
              InputProps={{ readOnly: this.props.readonly }}
              margin="dense"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              id="outlined-name"
              label="City"
              placeholder={"City"}
              value={this.props.value.dbObj.city || ""}
              type={"text"}
              onChange={this.props.onChange}
              InputProps={{ readOnly: this.props.readonly }}
              margin="dense"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              id="outlined-name"
              label="State"
              placeholder={"State"}
              value={this.props.value.dbObj.state || ""}
              type={"text"}
              onChange={this.props.onChange}
              InputProps={{ readOnly: this.props.readonly }}
              margin="dense"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item md={2} xs={12}>
            <TextField
              id="outlined-name"
              label="Zip Code"
              placeholder={"Zip Code"}
              value={this.props.value.dbObj.zip || ""}
              type={"text"}
              onChange={this.props.onChange}
              InputProps={{ readOnly: this.props.readonly }}
              margin="dense"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item md={2} xs={12}>
            <TextField
              id="outlined-name"
              label="Zip4"
              placeholder={"Zip4"}
              value={this.props.value.dbObj.zip4 || ""}
              type={"text"}
              onChange={this.props.onChange}
              InputProps={{
                readOnly: this.props.readonly,
              }}
              margin="dense"
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid> */}
      </div>
    );
  }
}
export class CaserGoogleFolder extends CaserField {
  render() {
    var self = this;
    return (
      <a
        style={{ display: "inline-block" }}
        href={"https://drive.google.com/drive/u/0/folders/" + self.props.value}
        target={"_blank"}
      >
        <Button type="primary">Open Folder</Button>
      </a>
    );
  }
}
export class CaserDocumentMerge extends CaserField {
  state = {
    visible: false,
    innerVisible: false,
    templateID: null,
    menuOpen: false,
    anchor: null,
  };
  openFileForMerge = this.openFileForMerge.bind(this);
  // toggleMainMenu() {
  //     if (this.state.visible) {
  //         this.setState({ visible: false, innerVisible: false });
  //         return;
  //     }
  //     this.setState({ visible: true });
  // }
  // toggleInnerMenu() {
  //     if (this.state.innerVisible) {
  //         this.setState({ innerVisible: false });
  //         return;
  //     }
  //     this.setState({ innerVisible: true });
  // }

  // onClose() {
  //     this.setState({ visible: false, innerVisible: false });
  // }
  // onInnerClose() {
  //     this.setState({ innerVisible: false });
  // }

  async openFileForMerge(templateID) {
    // console.log(templateID);
    this.setState({ innerVisible: false, templateID: templateID });
    // try {
    //   await this.context.callFunctionSync("mergeTemplate", {
    //     template: this.props.templates[templateID].id,
    //     nodeID: this.props.reqID,
    //     tableName: this.props.field.t,
    //     rowID: this.props.field.id,
    //   });
    // } catch (Err) {
    //   console.log(Err);
    // }
  }
  render() {
    var self = this;
    return (
      <div>
        <Button
          type="primary"
          onClick={(e) =>
            self.setState({ menuOpen: true, anchor: e.currentTarget })
          }
        >
          Merge Document
        </Button>
        <MenuMUI
          anchorEl={this.state.anchor}
          // keepMounted
          open={this.state.menuOpen}
          onClose={() => self.setState({ menuOpen: false })}
        >
          {this.props.templates.map((val, index) => (
            <MenuItemMUI
              onClick={() => {
                self.openFileForMerge(index);
                self.setState({ menuOpen: false });
              }}
              key={index}
            >
              {val.name}
            </MenuItemMUI>
          ))}
        </MenuMUI>
      </div>
    );
  }
}

export class DataElement extends React.Component {
  // class = { f: this.props.s}
  constructor(props) {
    super(props);
    if (this.props.log) setInterval(() => console.log(this.props.s), 1000);
  }
  render() {
    // console.log("render");
    if (!this.props.f) {
      this.props.f = (s) => s;
    }
    var ret = null;
    try {
      ret = this.props.f(this.props.s);
      // console.log("settingelements");
    } catch (err) {}
    return <span>{ret}</span>;
  }
}
export default Field;
