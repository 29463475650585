import React from 'react'
import {Button} from 'antd';

export default class ConfirmDialog extends React.Component {
    state = {
        isConfirming:false,
        timeLeft:0
    }
    _ismounted = false;
    componentDidMount() {
        this._ismounted = true;
    }

    componentWillUnmount() {
        this._ismounted = false;
    }
    click() {
        if (this.state.isConfirming === true) {
            this.props.onClick();
        }
        else {
            this.setState({ timeLeft: 3, isConfirming: true });
            var self = this;
            const interval = () => {
                if (self._ismounted === true) {
                    if (self.state.timeLeft > 1) {
                        self.setState({ timeLeft: self.state.timeLeft-1});
                        setTimeout(interval, 1000);
                    }
                    else {
                        this.setState({ timeLeft: 0, isConfirming: false });
                    }
                }
            }
            setTimeout(interval,1000);
        }
    }
    render() {
        return(
            <Button onClick={this.click.bind(this)} type="danger" ghost>{this.state.isConfirming ? "Are You Sure? (" + this.state.timeLeft+")":this.props.children }</Button>
        )
    }
}