import React, { Component } from "react";
import FeathersContext from "../../feathersContext";
import Dashboard from "./Dashboard";
export default class DashboardContainer extends Component {
  static contextType = FeathersContext;
  state = {
    user: null,
  };
  async componentDidMount() {
    const { user } = await this.context.app.get("authentication");
    this.setState({ user });
    console.log(user);
    document.querySelector('#my-manifest-placeholder').setAttribute('href', '/icons/manifest.json');

  }

  render() {
    return <Dashboard {...this.props} user={this.state.user} />;
  }
}
