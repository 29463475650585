import React, { Component } from "react";
import FeathersContext from "../../feathersContext";
import { useTable, useSortBy } from "react-table";

import Loading from "../general/Loading";
import "react-h5-audio-player/lib/styles.css";
import { Typography } from "@material-ui/core";
import moment from "moment";
export default class Dashboard extends Component {
    static contextType = FeathersContext;
    state = {
        ...this.props.match.params,
        attendees: [],
        // registrations: [],
    };
    async componentDidMount() {
        this.getData();
        this.addListeners();
    }

    getData = async (liturgy = null) => {
        if (liturgy === null)
            liturgy = await this.context.app
                .service("liturgies")
                .get(this.state.liturgyId);
        let attendees = [];
        for (let reg of liturgy.registrations) {
            attendees.push(
                ...reg.attendees
                    .filter(
                        (att) =>
                            att.cancelled !== true
                    )
                    .map((v, i) => {
                        return {
                            firstName: v.firstName,
                            lastName: v.lastName,
                            age: !v.underage ? "Adult" : "Child",
                            hohName:
                                i === 0
                                    ? "Self"
                                    : reg.contact.firstName +
                                      " " +
                                      reg.contact.lastName,
                            hohPhone: this.displayPhoneNumber(
                                reg.contact.phoneNumber
                            ),
                            hohEmail: reg.contact.email,
                            attendance:
                                v.attended !== true && v.attended !== false
                                    ? ""
                                    : v.attended
                                    ? "Attended"
                                    : "Did Not Attend",
                        };
                    })
            );
        }
        this.setState({
            liturgy,
            attendees,
            // registrations: registrations.data,
        });
        console.log(liturgy, attendees);
    };
    addListeners = () => {
        this.context.app.service("registrations").on("created", (r) => {
            if (r.liturgyId === this.state.liturgyId) this.getData();
        });
        this.context.app.service("liturgies").on("patched", (liturgy) => {
            if (liturgy._id === this.state.liturgyId) this.getData(liturgy);
        });
        this.context.app.service("registrations").on("patched", (r) => {
            if (r.liturgyId === this.state.liturgyId) this.getData();
        });
        this.context.app.service("registrations").on("removed", (r) => {
            if (r.liturgyId === this.state.liturgyId) this.getData();
        });
    };
    displayPhoneNumber(number) {
        let onlyNumber = number.replace(/\D/g, "");
        if (onlyNumber.length < 10) return number;
        if (onlyNumber.length > 11) return number;
        if (onlyNumber.length === 10) onlyNumber = "1" + onlyNumber;
        return (
            "(" +
            onlyNumber[1] +
            onlyNumber[2] +
            onlyNumber[3] +
            ") " +
            onlyNumber.substring(4, 7) +
            "-" +
            onlyNumber.substring(7)
        );
    }
    columns = [
        {
            Header: "First Name",
            accessor: "firstName",
        },
        {
            Header: "Last Name",
            accessor: "lastName",
        },
        {
            Header: "Age",
            accessor: "age",
        },
        {
            Header: "Head of Household",
            accessor: "hohName",
        },

        {
            Header: "Phone Number",
            accessor: "hohPhone",
            minWidth: 200,
            width: 200,
        },
        {
            Header: "Email Address",
            accessor: "hohEmail",
        },
        {
            Header: "Attendance Status",
            accessor: "attendance",
        },
    ];
    render() {
        if (this.state.attendees.length === 0) return <Loading />;

        return (
            <>
                <Typography variant={"h5"} gutterBottom>
                    Confirmed Attendees of the Liturgy on{" "}
                    {moment(this.state.liturgy.liturgyDate).format("ll")}
                </Typography>
                <Table columns={this.columns} data={this.state.attendees} />
            </>
        );
    }
}

function Table({ columns, data }) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
            initialState: {
                sortBy: [{ id: "lastName" }, { id: "firstName" }],
            },
        },
        useSortBy
    );

    const firstPageRows = rows;
    console.log(headerGroups);
    return (
        <>
            <table {...getTableProps()}>
                <thead>
                    <tr role="row" {...headerGroups[0].getHeaderGroupProps()}>
                        <th
                            {...headerGroups[0].headers[0].getHeaderProps(
                                headerGroups[0].headers[0].getSortByToggleProps()
                            )}
                            colspan="1"
                            role="columnheader"
                            title="Toggle SortBy"
                        >
                            First Name
                            <span>
                                {headerGroups[0].headers[0].isSorted
                                    ? headerGroups[0].headers[0].isSortedDesc
                                        ? " 🔽"
                                        : " 🔼"
                                    : ""}
                            </span>
                        </th>
                        <th
                            colspan="1"
                            role="columnheader"
                            title="Toggle SortBy"
                            {...headerGroups[0].headers[1].getHeaderProps(
                                headerGroups[0].headers[1].getSortByToggleProps()
                            )}
                        >
                            Last Name
                            <span>
                                {headerGroups[0].headers[1].isSorted
                                    ? headerGroups[0].headers[1].isSortedDesc
                                        ? " 🔽"
                                        : " 🔼"
                                    : ""}
                            </span>
                        </th>
                        <th
                            colspan="1"
                            role="columnheader"
                            title="Toggle SortBy"
                            {...headerGroups[0].headers[2].getHeaderProps(
                                headerGroups[0].headers[2].getSortByToggleProps()
                            )}
                        >
                            Age
                            <span>
                                {headerGroups[0].headers[2].isSorted
                                    ? headerGroups[0].headers[2].isSortedDesc
                                        ? " 🔽"
                                        : " 🔼"
                                    : ""}
                            </span>
                        </th>
                        <th
                            colspan="1"
                            role="columnheader"
                            title="Toggle SortBy"
                            {...headerGroups[0].headers[3].getHeaderProps(
                                headerGroups[0].headers[3].getSortByToggleProps()
                            )}
                        >
                            Head of Household
                            <span>
                                {headerGroups[0].headers[3].isSorted
                                    ? headerGroups[0].headers[3].isSortedDesc
                                        ? " 🔽"
                                        : " 🔼"
                                    : ""}
                            </span>
                        </th>
                        <th
                            colspan="1"
                            role="columnheader"
                            title="Toggle SortBy"
                            {...headerGroups[0].headers[4].getHeaderProps(
                                headerGroups[0].headers[4].getSortByToggleProps()
                            )}
                            className={"phone"}
                        >
                            Telephone Number
                            <span>
                                {headerGroups[0].headers[4].isSorted
                                    ? headerGroups[0].headers[4].isSortedDesc
                                        ? " 🔽"
                                        : " 🔼"
                                    : ""}
                            </span>
                        </th>
                        <th
                            colspan="1"
                            role="columnheader"
                            title="Toggle SortBy"
                            {...headerGroups[0].headers[5].getHeaderProps(
                                headerGroups[0].headers[5].getSortByToggleProps()
                            )}
                        >
                            Email Address
                            <span>
                                {headerGroups[0].headers[5].isSorted
                                    ? headerGroups[0].headers[5].isSortedDesc
                                        ? " 🔽"
                                        : " 🔼"
                                    : ""}
                            </span>
                        </th>
                        <th
                            colspan="1"
                            role="columnheader"
                            title="Toggle SortBy"
                            {...headerGroups[0].headers[6].getHeaderProps(
                                headerGroups[0].headers[6].getSortByToggleProps()
                            )}
                        >
                            Attendance Status
                            <span>
                                {headerGroups[0].headers[6].isSorted
                                    ? headerGroups[0].headers[6].isSortedDesc
                                        ? " 🔽"
                                        : " 🔼"
                                    : ""}
                            </span>
                        </th>
                    </tr>
                    {/* {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th
                                    {...column.getHeaderProps(
                                        column.getSortByToggleProps()
                                    )}
                                >
                                    {column.render("Header")}
                                    <span>
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? " 🔽"
                                                : " 🔼"
                                            : ""}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))} */}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {firstPageRows.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                    return (
                                        <td {...cell.getCellProps()}>
                                            {cell.render("Cell")}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </>
    );
}
