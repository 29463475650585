import React, { Component } from "react";
import FeathersContext from "../../feathersContext";
import Collapsable,{CollapsablePanel} from "../../components/mainComponents/collapsable"
import {CaserLongTextField, CaserPhoneField, CaserRadioField, CaserTextField} from "../../components/mainComponents/Fields"
import Card from "../../components/mainComponents/Card"
import TextBar from "../../components/mainComponents/textBar";
import Confirm from "../../components/mainComponents/confirmButton";
import { Button } from "@material-ui/core";
import {RiCheckboxBlankCircleLine} from "react-icons/ri"
import {FiCheckCircle} from "react-icons/fi"
class Dashboard extends Component {
    static contextType = FeathersContext;
    state = {
        subscribers: [],
        groups: [],
        data: {},
    };
    async componentDidMount() {
        this.getData();
        this.getGroups();
        this.addListeners();
    }

    getData = async () => {
        let subscribers = await this.context.app.service("text-subscribers").find({});
        this.setState({
            subscribers: subscribers.data,
        });
    };
    getGroups = async () => {
        let groups = await this.context.app.service("text-groups").find({});
        this.setState({
            groups: groups.data,
        });

    }
    addListeners = () => {
        this.context.app.service("text-subscribers").on("created", this.getData);
        this.context.app.service("text-subscribers").on("patched", this.getData);
        this.context.app.service("text-subscribers").on("removed", this.getData);
        this.context.app.service("text-groups").on("created", this.getGroups);
        this.context.app.service("text-groups").on("patched", this.getGroups);
        this.context.app.service("text-groups").on("removed", this.getGroups);
    };
    render() {
        return <Card header={
            <TextBar         right={
                <Button

                  style={{ color: "#fff" }}
                  onClick={()=>this.context.app.service("text-groups").create({name:"New Group"})}
                >
                 Add Group
                 </Button>
              }
      >Groups</TextBar>
        }><Collapsable>
        {this.state.groups.filter(v=>v.default!==true).map(group=>
            
            (
                <CollapsablePanel key={group._id} header={group.name} renderClosed={false}>
                    <Group parent={this} group={group} />
                </CollapsablePanel>
            ))}
        </Collapsable></Card>;
    }
}
class Group extends Component {
    editGroup = async (sID,node,val) => {
        await this.props.parent.context.app.service("text-groups").patch(sID,{[node]:val});
        this.props.parent.setState(state=>{
            for(let [ind,sub] of state.groups.entries()) {
                if (sID === sub._id) {
                    state.groups[ind][node] = val
                    break;
                }
            }
            return state;
        })
    }
    editSubscriber = async (sID,node,val) => {
        await this.props.parent.context.app.service("text-subscribers").patch(sID,{[node]:val});
        this.props.parent.setState(state=>{
            for(let [ind,sub] of state.subscribers.entries()) {
                if (sID === sub._id) {
                    state.subscribers[ind][node] = val
                    break;
                }
            }
            return state;
        })
    }
    toggleSubscription(subscriberID) {
        let index = this.props.parent.state.subscribers.findIndex(v=>v._id===subscriberID);
        let sub = this.props.parent.state.subscribers[index];
        let groups = sub.groups || [];
        if (groups.includes(this.props.group._id)) {
            groups.splice(groups.indexOf(this.props.group._id),1)
            this.editSubscriber(subscriberID,"groups",groups)
        } else {
            groups.push(this.props.group._id)
            this.editSubscriber(subscriberID,"groups",groups)
        }
    }
    deleteGroup = async ()=> {
        if (this.props.group.default) return;
        for (let sub of this.props.paerent.state.subscribers) {
            let index = ((sub.groups||[]).indexOf(this.props.group._id))
            if (index !== -1) {
                let groups = sub.groups;
                groups.splice(index,1);
                await this.props.parent.context.app.service("text-subscribers").patch(sub._id,{groups});
            }
        }
        await this.props.parent.context.app.service("text-groups").delete(this.props.group._id);

    }
    render() {
        return (
            <div>
            <CaserTextField
            label={"Name"}
            noDebounce
            onChange={(val)=>{
                this.editGroup(this.props.group._id,"name",val)
            }}
            value={this.props.group.name}
            />
            {

            // <Confirm onClick={this.deleteGroup}>Delete Group</Confirm>
            }
            {this.props.parent.state.subscribers.filter(v=>v.Active).sort(
                (a,b) =>{
                    if ((a.lastName||"zz") >(b.lastName||"zz")) return 1;
                    if ((a.lastName||"zz") <(b.lastName||"zz")) return -1;
                    return 0;
                }
            ).map(subscriber=>(
            <div key={subscriber._id} onClick={()=>{this.toggleSubscription(subscriber._id)}} style={{userSelect:"none", cursor:"pointer", borderRadius:"5px",background:"#eee",padding:"10px",fontSize:"20px",margin:"5px"}}>{(subscriber.groups||[]).includes(this.props.group._id)?<FiCheckCircle style={{marginRight:10,color:"green"}} />:<RiCheckboxBlankCircleLine style={{marginRight:10,color:"#aaa"}} />}{subscriber.firstName && subscriber.lastName? subscriber.lastName+", "+  subscriber.firstName : "Unknown - "+ subscriber.PhoneNumber}
                </div>
            ))}
            </div>
            )
}
}
export default Dashboard;
