import React from "react";
// import { Route, Switch, Redirect } from "react-router-dom";
// import FeathersContext from "../../feathersContext";
import Groups from "./Groups";
import Messages from "./Messages";
import New from "./New";
import People from "./People";
import Tabs,{Tab} from "../../components/mainComponents/Tabs"

export default function DashboardContainer() {
    document.querySelector('#my-manifest-placeholder').setAttribute('href', '/icons/manifest-texting.json');

    // static contextType = FeathersContext;
    // state = {
    //     user: null,
    // };
    // async componentDidMount() {
    //     const { user } = await this.context.app.get("authentication");
    //     this.setState({ user });
    // }

        return (

<Tabs>
                   <Tab label={"New"} renderClosed>
                     <New />
                 </Tab>
                 <Tab label={"People"} renderClosed>
                     <People />
                 </Tab>
                <Tab label={"Groups"} renderClosed>
                     <Groups />
                 </Tab> 
                  <Tab label={"Messages"} renderClosed> 
                     <Messages />
                  </Tab>  
              </Tabs>         
        );
}


