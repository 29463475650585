import React from "react";
import { withFormsy } from "formsy-react";
import {
    Select,
    InputLabel,
    TextField,
    RadioGroup,
    FormControl,
    FormLabel,
    ButtonGroup,
    Button,
} from "@material-ui/core";
import moment from "moment";
import MomentUtils from "@date-io/moment";

import {
    DatePicker,
    TimePicker,
    DateTimePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";

let TextInput = withFormsy(
    class extends React.Component {
        render() {
            return (
                <div style={{ padding: "15px 0px" }}>
                    <TextField
                        size={this.props.variant || "small"}
                        type={this.props.type || "text"}
                        variant={this.props.variant || "outlined"}
                        fullWidth
                        multiline={this.props.multiline || false}
                        error={this.props.showError}
                        required={this.props.isRequired}
                        label={this.props.label}
                        value={this.props.value}
                        onBlur={(e) => this.props.setValue(e.target.value)}
                        onChange={(e) => {
                            this.props.setValue(e.target.value, false);
                            if (typeof this.props.onChange === "function")
                                this.props.onChange(e.target.value);
                        }}
                        formNoValidate
                        inputProps={{ readOnly: this.props.readOnly || false }}
                        InputLabelProps={this.props.InputLabelProps || {}}
                        helperText={
                            this.props.showError
                                ? this.props.errorMessage
                                : this.props.helperText
                        }
                    />
                </div>
            );
        }
    }
);
export default TextInput;

let DateInput = withFormsy(
    class extends React.Component {
        render() {
            return (
                <div style={{ padding: "15px 0px" }}>
                    <MuiPickersUtilsProvider
                        libInstance={moment}
                        utils={MomentUtils}
                    >
                        <DatePicker
                            // format={this.props.format ||"MM/dd/yyyy"}
                            fullWidth
                            size={this.props.variant || "small"}
                            inputVariant={this.props.variant || "outlined"}
                            error={this.props.showError}
                            required={this.props.isRequired}
                            label={this.props.label}
                            value={this.props.value || ""}
                            disabled={this.props.disabled || false}
                            disableFuture={this.props.disableFuture || false}
                            disablePast={this.props.disablePast || false}
                            disableToolbar={this.props.disableToolbar || false}
                            onBlur={(e) => this.props.setValue(e.target.value)}
                            onChange={(v) => {
                                this.props.setValue(v, false);
                                if (typeof this.props.onChange === "function")
                                    this.props.onChange(v);
                            }}
                            helperText={
                                this.props.showError
                                    ? this.props.errorMessage
                                    : this.props.helperText
                            }
                        />
                    </MuiPickersUtilsProvider>
                </div>
            );
        }
    }
);

let TimeInput = withFormsy(
    class extends React.Component {
        render() {
            return (
                <div style={{ padding: "15px 0px" }}>
                    <MuiPickersUtilsProvider
                        libInstance={moment}
                        utils={MomentUtils}
                    >
                        <TimePicker
                            // format={this.props.format ||"MM/dd/yyyy"}
                            fullWidth
                            size={this.props.variant || "small"}
                            inputVariant={this.props.variant || "outlined"}
                            error={this.props.showError}
                            required={this.props.isRequired}
                            label={this.props.label}
                            disabled={this.props.disabled || false}
                            disableFuture={this.props.disableFuture || false}
                            disablePast={this.props.disablePast || false}
                            disableToolbar={this.props.disableToolbar || false}
                            value={this.props.value || ""}
                            onBlur={(e) => this.props.setValue(e.target.value)}
                            onChange={(v) => {
                                this.props.setValue(v, false);
                                if (typeof this.props.onChange === "function")
                                    this.props.onChange(v);
                            }}
                            helperText={
                                this.props.showError
                                    ? this.props.errorMessage
                                    : this.props.helperText
                            }
                        />
                    </MuiPickersUtilsProvider>
                </div>
            );
        }
    }
);
let DateTimeInput = withFormsy(
    class extends React.Component {
        render() {
            return (
                <div style={{ padding: "15px 0px" }}>
                    <MuiPickersUtilsProvider
                        libInstance={moment}
                        utils={MomentUtils}
                    >
                        <DateTimePicker
                            fullWidth
                            size={this.props.variant || "small"}
                            inputVariant={this.props.variant || "outlined"}
                            error={this.props.showError}
                            required={this.props.isRequired}
                            label={this.props.label}
                            value={this.props.value || ""}
                            disabled={this.props.disabled || false}
                            disableFuture={this.props.disableFuture || false}
                            disablePast={this.props.disablePast || false}
                            disableToolbar={this.props.disableToolbar || false}
                            onBlur={(e) => this.props.setValue(e.target.value)}
                            onChange={(v) => {
                                this.props.setValue(v, false);
                                if (typeof this.props.onChange === "function")
                                    this.props.onChange(v);
                            }}
                            helperText={
                                this.props.showError
                                    ? this.props.errorMessage
                                    : this.props.helperText
                            }
                        />
                    </MuiPickersUtilsProvider>
                </div>
            );
        }
    }
);

let SelectInput = withFormsy(
    class extends React.Component {
        render() {
            return (
                <div style={{ padding: "15px 0px" }}>
                    <FormControl
                        size={this.props.variant || "small"}
                        fullWidth
                        variant={this.props.variant || "outlined"}
                    >
                        <InputLabel id={this.props.label}>
                            {this.props.label}
                        </InputLabel>
                        <Select
                            fullWidth
                            size={this.props.variant || "small"}
                            variant={this.props.variant || "outlined"}
                            error={this.props.showError}
                            required={this.props.isRequired}
                            label={this.props.label}
                            value={this.props.value}
                            onChange={(e) => {
                                this.props.setValue(e.target.value);
                                if (typeof this.props.onChange === "function")
                                    this.props.onChange(e.target.value);
                            }}
                            formNoValidate
                            helperText={
                                this.props.showError
                                    ? this.props.errorMessage
                                    : this.props.helperText
                            }
                        >
                            {this.props.children}
                        </Select>
                    </FormControl>
                </div>
            );
        }
    }
);

let ButtonGroupInput = withFormsy(
    class extends React.Component {
        render() {
            return (
                <div style={{ padding: "15px 0px" }}>
                    <FormControl component="fieldset" fullWidth>
                        <FormLabel
                            required={this.props.isRequired && this.props.label}
                        >
                            {this.props.label}
                        </FormLabel>
                        <RadioGroup
                            fullWidth
                            error={this.props.showError}
                            name={this.props.name}
                            required={this.props.isRequired}
                            value={this.props.value}
                            formNoValidate
                            helperText={
                                this.props.showError
                                    ? this.props.errorMessage
                                    : this.props.helperText
                            }
                        >
                            <ButtonGroup
                                fullWidth
                                size={this.props.size || "normal"}
                                color={this.props.color || "default"}
                                style={{ marginTop: 5 }}
                                variant={this.props.variant || "outlined"}
                            >
                                {this.props.options.map((v, i) => (
                                    <Button
                                        key={i}
                                        onClick={() => {
                                            this.props.setValue(v.value);
                                            if (
                                                typeof this.props.onChange ===
                                                "function"
                                            )
                                                this.props.onChange(v.value);
                                        }}
                                        variant={
                                            v.value === this.props.value
                                                ? "contained"
                                                : "outlined"
                                        }
                                    >
                                        {v.label}
                                    </Button>
                                ))}
                            </ButtonGroup>
                        </RadioGroup>
                    </FormControl>
                </div>
            );
        }
    }
);
export { SelectInput, DateInput, TimeInput, DateTimeInput, ButtonGroupInput };
