import React, { Component } from "react";
import FeathersContext from "../../feathersContext";
// import MuiAlert from "@material-ui/lab/Alert";
// import Loading from "../general/Loading";
// import Formsy from "formsy-react";
import {
    // RadioGroup,
    // FormControl,
    // FormLabel,
    // ButtonGroup,
    // Button,
    // Typography,
    // MenuItem,
    // Card,
    // CardContent,
    // CardActions,
    // // ExpansionPanel,
    // // ExpansionPanelSummary,
    // // ExpansionPanelDetails,
    // // Chip,
    // // ExpansionPanelActions,
    // // Box,
    // TextField,
    // Paper,
    // CircularProgress,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Card from "../../components/mainComponents/Card"
import Collapsable,{CollapsablePanel} from "../../components/mainComponents/collapsable"
import moment from "moment";
class Dashboard extends Component {
    static contextType = FeathersContext;
    state = {
        texts: [],
        groups: [],
        data: {},
    };
    async componentDidMount() {
        this.getData();
        this.addListeners();
    }

    getData = async () => {
        let texts = await this.context.app.service("texts").find({});
        this.setState({
            texts: texts.data,
        });
    };
    addListeners = () => {
        this.context.app.service("texts").on("created", this.getData);
        this.context.app.service("texts").on("patched", this.getData);
        this.context.app.service("texts").on("removed", this.getData);
    };
    render() {
        let data = {};
        for (let datum of this.state.texts) {
            data[datum._id] = datum;
        }

        return (
            <div>
                {this.state.texts.reverse().map((text,index)=>(
                    <div key={index}>
                       <p style={{fontSize:"12px",margin:"5px 15px",fontWeight:800}}>{text.datetime? moment(text.datetime).format("lll"):null} To: {text.group||"all"}</p>
                <div style={{padding:10,margin:"0px 10px",marginBottom:"15px", borderRadius:10,background:"#eee",fontWeight:800}}>{text.message}</div>
                        {/* <p><b>Status: </b>{text.status}</p> */}
                    </div>
                ))}

            </div>
        )
}
}
export default Dashboard;
