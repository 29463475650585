import React, { Component } from "react";
// import FeathersData from "../../components/FeathersData";
import FeathersContext from "../../feathersContext";
import Collapsable,{CollapsablePanel} from "../../components/mainComponents/collapsable"
import {CaserLongTextField, CaserPhoneField, CaserRadioField, CaserTextField} from "../../components/mainComponents/Fields"
class Dashboard extends Component {
    static contextType = FeathersContext;
    state = {
        subscribers: [],
        groups: [],
        data: {},
    };
    async componentDidMount() {
        this.getData();
        this.getGroups();
        this.addListeners();
        // this.updateNames();
    }

    getData = async () => {
        let subscribers = await this.context.app.service("text-subscribers").find({});
        this.setState({
            subscribers: subscribers.data,
        });
    };
    getGroups = async () => {
        let groups = await this.context.app.service("text-groups").find({});
        this.setState({
            groups: groups.data,
        });

    }
    addListeners = () => {
        this.context.app.service("text-subscribers").on("created", this.getData);
        // this.context.app.service("text-subscribers").on("patched", this.getData);
        this.context.app.service("text-subscribers").on("removed", this.getData);
        this.context.app.service("text-groups").on("created", this.getGroups);
        this.context.app.service("text-groups").on("patched", this.getGroups);
        this.context.app.service("text-groups").on("removed", this.getGroups);
    };
    updateNames = async () => {
        let subscribers = await this.context.app.service("text-subscribers").find({});
        for (let sub of subscribers.data) {
            // if (sub.firstName && sub.lastName) continue;

            // let registrations = await this.context.app.service("registrations").find({
            //     query: {
            //         "contact.phoneNumber": sub.PhoneNumber,
            // }});
            // if (registrations.data.length) {
            //     let mostCommon = registrations.data.reduce((p,v)=>{
            //         v.contact.lastName = v.contact.lastName.trim();
            //         v.contact.firstName = v.contact.firstName.trim();
            //         let index = p.findIndex(val=>val.firstName===v.contact.firstName && val.lastName===v.contact.lastName) 
            //         if (index !== -1) {
            //             ++p[index].quantity
            //         }
            //         else {
            //             p.push({firstName:v.contact.firstName,lastName:v.contact.lastName,quantity:1})
            //         }
                    
            //         return p
            //     },[]).sort((a,b)=>a.quantity<b.quantity)[0]
            //     await this.context.app.service("text-subscribers").patch(sub._id,{firstName:mostCommon.firstName,lastName:mostCommon.lastName});

            //     console.log("Found: ",mostCommon)
            //     continue;
            // }
            // else console.log("Not Found")
            
            
            // let hotlineCalls = await this.context.app.service("hotlinevoicemails").find({
            //     query: {
            //         "from": sub.PhoneNumber,
            // }});
            // if (hotlineCalls.data.length) {
            //     let mostCommonHotline = hotlineCalls.data.reduce((p,v)=>{
            //         // console.log(v)
            //         if (!v.firstName || !v.lastName) return p;
            //         v.lastName = v.lastName.trim();
            //         v.firstName = v.firstName.trim();
            //         let index = p.findIndex(val=>val.firstName===v.firstName && val.lastName===v.lastName) 
            //         if (index !== -1) {
            //             ++p[index].quantity
            //         }
            //         else {
            //             p.push({firstName:v.firstName,lastName:v.lastName,quantity:1})
            //         }
            //         return p
            //     },[]).sort((a,b)=>a.quantity<b.quantity)[0]
            //     if (mostCommonHotline) await this.context.app.service("text-subscribers").patch(sub._id,{firstName:mostCommonHotline.firstName,lastName:mostCommonHotline.lastName});

            //     console.log("Found v: ",mostCommonHotline)
            //     continue;
            // }
            // else console.log("Not Found")

            // let electionHotlineCalls = await this.context.app.service("hotlinevoicemails").find({
            //     query: {
            //         "from": sub.PhoneNumber,
            // }});
            // if (electionHotlineCalls.data.length) {
            //     let mostCommonHotline = electionHotlineCalls.data.reduce((p,v)=>{
            //         // console.log(v)
            //         if (!v.firstName || !v.lastName) return p;
            //         v.lastName = v.lastName.trim();
            //         v.firstName = v.firstName.trim();
            //         let index = p.findIndex(val=>val.firstName===v.firstName && val.lastName===v.lastName) 
            //         if (index !== -1) {
            //             ++p[index].quantity
            //         }
            //         else {
            //             p.push({firstName:v.firstName,lastName:v.lastName,quantity:1})
            //         }
            //         return p
            //     },[]).sort((a,b)=>a.quantity<b.quantity)[0]
            //     if (mostCommonHotline) await this.context.app.service("text-subscribers").patch(sub._id,{firstName:mostCommonHotline.firstName,lastName:mostCommonHotline.lastName});

            //     console.log("Found v: ",mostCommonHotline)
            //     continue;
            // }
            // else console.log("Not Found")




            // let electionRegistration = list.find(v=>v.PhoneNumber.toString()===sub.PhoneNumber);
            
            // if (electionRegistration) {
            //     if ((sub.firstName||"").toLowerCase().trim() ===  electionRegistration.Firstname.toLowerCase().trim()) continue;
            //   await this.context.app.service("text-subscribers").patch(sub._id,{firstName:electionRegistration.Firstname,lastName:electionRegistration.Lastname});

            //     console.log("Found v: ",electionRegistration.Firstname,sub.firstName)
            //     continue;
            // }
            // else console.log("Not Found")

        }

    }
    render() {
        return (
            <Collapsable>
            {(this.state.subscribers||[]).sort(
                (a,b) =>{
                    if ((a.lastName||"zz") >(b.lastName||"zz")) return 1;
                    if ((a.lastName||"zz") <(b.lastName||"zz")) return -1;
                    return 0;
                }
            ).map((subscriber)=>(
                <CollapsablePanel key={subscriber._id} header={<textBar style={{color:subscriber.Active?"#000":"#888"}}>
                    {subscriber.firstName && subscriber.lastName? subscriber.lastName+", "+  subscriber.firstName : "Unknown - "+ subscriber.PhoneNumber}
                </textBar>
                }>
                     <Subscriber subscriber={subscriber} parent={this} />
                </CollapsablePanel>
            ))}

        </Collapsable>
        );
    }
}
class Subscriber extends Component {
    editSubscriber = async (sID,node,val) => {
        await this.props.parent.context.app.service("text-subscribers").patch(sID,{[node]:val});
        this.props.parent.setState(state=>{
            for(let [ind,sub] of state.subscribers.entries()) {
                if (sID === sub._id) {
                    state.subscribers[ind][node] = val
                    break;
                }
            }
            return state;
        })
        
        
    }
    render() {
        return (
            <div>
            <CaserRadioField
                        label={"Subscribed"}

                        onChange={(val)=>{
                            this.editSubscriber(this.props.subscriber._id,"Active",val)
                        }}
            
            opts={[{optionId:true,label:"Active"},{optionId:false,label:"Inactive"}]}
                        value={this.props.subscriber.Active}
            />

            <CaserTextField
            label={"First Name"}
            noDebounce
            onChange={(val)=>{
                this.editSubscriber(this.props.subscriber._id,"firstName",val)
            }}
            value={this.props.subscriber.firstName}
            />
            <CaserTextField
            label={"Last Name"}
            noDebounce
            onChange={(val)=>{
                this.editSubscriber(this.props.subscriber._id,"lastName",val)
            }}
            value={this.props.subscriber.lastName}
            />

             <CaserPhoneField
            label={"Phone Number"}
            onChange={(val)=>{
                this.editSubscriber(this.props.subscriber._id,"PhoneNumber",val)
            }}
            value={this.props.subscriber.PhoneNumber}
            />
            <CaserLongTextField
            label={"Notes"}
            onChange={(val)=>{
                this.editSubscriber(this.props.subscriber._id,"Notes",val)
            }}
            value={this.props.subscriber.Notes}
            />
            </div>
            )
}
}

export default Dashboard;
