import React, { Component } from "react";
import {
  Container,
  Typography,
  Button,
  Card,
  CardContent,
  CardActionArea,
  CardMedia,
  Link,
  CardActions,
  ButtonGroup,
} from "@material-ui/core";
import FeathersContext from "../../feathersContext";
import momentTZ from "moment-timezone";
import moment from "moment";
import step3 from "./Step3.png";
import step4a from "./4a.png";
import step4b from "./4b.png";
import step5 from "./5.png";
export default class PublicSite extends Component {
  static contextType = FeathersContext;
  state = {
    liturgies: null,
    buttonIsNotActive: false,
  };
  async componentDidMount() {
    this.getLiturgies();
    let ServerTime = await this.context.app.service("time").get(1);

    this.setState({
      buttonIsNotActive: moment(ServerTime).isBefore(
        momentTZ("2020-03-21T12:00")
      ),
    });
    // console.log(ServerTime);
    console.log();
  }
  getLiturgies = async () => {
    this.setState({ liturgies: null });
    let liturgies = await this.context.app
      .service("liturgies")
      .find({ query: { available: true } });
    liturgies.data = liturgies.data.map((v) => {
      v.label = moment(v.liturgyDate).format("dddd MMMM D YYYY, h:mm a");
      if (v.totalAttending > 49) v.label += " (Full)";
      else if (v.totalAttending === 49)
        v.label += " (Only one spot left in this liturgy)";
      else
        v.label +=
          " (" + (50 - v.totalAttending) + " spots left in this liturgy)";

      return v;
    });
    console.log(liturgies.data);
    this.setState({ liturgies: liturgies.data });
  };

  render() {
    return (
      <div>
        <Container maxWidth={"md"}>
          <Typography variant={"h4"} color={"primary"}>
            Connect to Live Church Streaming Meetings
          </Typography>
          <br />
          <Card raised gutter>
            <CardContent>
              <Typography variant={"h5"}>
                1: Download and Install the Zoom app
              </Typography>
              <Typography>
                Click the link below to download the Zoom app, then install the
                application. If the app asks you to sign in, don’t worry, you do
                not need an account. Just close the app and continue to step 2.
              </Typography>
            </CardContent>
            <CardActions>
              <Link
                underline="none"
                target="_blank"
                href="https://us02web.zoom.us/download#client_4meeting"
              >
                <Button variant="outlined" color="primary">
                  Download Zoom
                </Button>
              </Link>
            </CardActions>
          </Card>
          <br />
          <br />
          <br />
          <Card raised gutter>
            <CardContent>
              <Typography variant={"h5"}>2: Go to the Meeting</Typography>
              <Typography>
                Click the link below to go to the meeting.
                (https://saintmarydelray.com/zoom)
              </Typography>
            </CardContent>
            <CardActions>
              <Link underline="none" target="_blank" href="/zoom">
                <Button variant={"contained"} size={"large"} color="primary">
                  Enter Meeting Now
                </Button>
              </Link>
            </CardActions>
          </Card>
          <br />
          <br />
        </Container>
      </div>
    );
  }
}
