import React, { Component } from "react";
import FeathersContext from "../../feathersContext";
// import { debounce } from "throttle-debounce";
import Loading from "../general/Loading";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import {
  ButtonGroup,
  Button,
  Typography,
  Chip,
  Box,
  MenuItem,
  Divider,
  InputBase,
} from "@material-ui/core";
import Card from "./../../components/mainComponents/Card";
import Collapsable, {
  CollapsablePanel,
} from "./../../components/mainComponents/collapsable";

import { fade, withStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import moment from "moment";
// import MomentUtils from "@date-io/moment";
// import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  CaserNumberField,
  CaserTextField,
  CaserSelectField,
  CaserDateTimeField,
  CaserRadioField,
} from "./../../components/mainComponents/Fields";
import Tabs, { Tab } from "./../../components/mainComponents/Tabs";
// function TabPanel(props) {
//   const { children, value, index } = props;
//   if (value === index) {
//     return <div>{children}</div>;
//   }
//   return null;
// }

class Dashboard extends Component {
  static contextType = FeathersContext;
  state = {
    ...this.props.match.params,
    tab: 0,
  };
  async componentDidMount() {}

  render() {
    return (
      <Tabs>
        <Tab label="Liturgies" renderClosed={false}>
          <Liturgies {...this.props} />
        </Tab>
        <Tab label="Voicemails" renderClosed={false}>
          <Voicemails {...this.props} />
        </Tab>
        <Tab label="Lock" renderClosed={false}>
          <Lock {...this.props} />
        </Tab>
        <Tab label="Rejections" renderClosed={false}>
          <Rejections {...this.props} />
        </Tab>
      </Tabs>
    );
  }
}
export default Dashboard;

class Liturgies extends Component {
  static contextType = FeathersContext;

  state = {
    ...this.props.match.params,
    liturgies: [],
    data: {},
    config: {},
    confirmingUsers: [],
    serviceGroups: [],
    chosenGroup: null,
    tab: 0,
  };
  async componentDidMount() {
    this.getData();
    this.addListeners();
  }
  async componentWillUnmount() {
    this.removeListeners();
  }
  getData = async () => {
    this.setState({
      liturgies: [],
    });
    let config = await this.context.app.service("config").find({});
    let res = { examClosedMessage: {} };
    for (let configO of config.data) {
      res[configO.key] = configO;
    }
    // await this.context.app
    //     .service("config").create({key:"defaultServiceGroup",value:"Round 3"})

    let confirmingUsers = (
      await this.context.app
        .service("users")
        .find({ query: { confirmer: true } })
    ).data;
    this.setState({
      confirmingUsers,
    });
    let groups = await this.context.app
      .service("liturgies")
      .update(1, { groups: true });
    let liturgies = await this.context.app.service("liturgies").find({
      query: {
        serviceGroup: this.state.chosenGroup || res.defaultServiceGroup.value,
      },
    });
    this.setState({
      liturgies: liturgies.data,
      serviceGroups: groups,
      config: res,
      chosenGroup: this.state.chosenGroup || res.defaultServiceGroup.value,
    });
  };
  setDefaultServiceGroup(groupName) {}
  getLiturgyData = async (liturgyID) => {
    let groups = await this.context.app
      .service("liturgies")
      .update(1, { groups: true });

    let liturgy = await this.context.app.service("liturgies").get(liturgyID);
    let liturgies = this.state.liturgies;
    liturgies[this.findLiturgyIndexByID(liturgyID)] = liturgy;
    this.setState({
      liturgies,
      serviceGroups: groups,
    });
  };

  handleDefaultGroupChange = async (group) => {
    this.setState((state) => {
      state.config.defaultServiceGroup.value = group;
      return state;
    });

    await this.context.app
      .service("config")
      .patch(this.state.config.defaultServiceGroup._id, { value: group });
  };

  addListeners = () => {
    this.context.app.service("liturgies").on("created", this.getData);
    this.context.app.service("registrations").on("created", (r) => {
      this.getLiturgyData(r.liturgyId);
    });

    this.context.app.service("liturgies").on("patched", (liturgy) => {
      let liturgies = this.state.liturgies;
      liturgies[this.findLiturgyIndexByID(liturgy._id)] = liturgy;
      this.setState({
        liturgies,
      });
    });
    this.context.app.service("registrations").on("patched", (r) => {
      this.getLiturgyData(r.liturgyId);
    });
  };
  removeListeners = () => {
    this.context.app.service("liturgies").off("created");
    this.context.app.service("registrations").off("created");
    this.context.app.service("liturgies").off("patched");
    this.context.app.service("registrations").on("patched");
  };
  setActiveCategory = (active) => {
    if (active !== this.state.category) {
      this.setState({ category: active });
    }
  };
  findLiturgyIndexByID = (id) => {
    for (let i in this.state.liturgies) {
      if (this.state.liturgies[i]._id === id) return i;
    }
    return -1;
  };
  handleLiturgyChange = async (id, model) => {
    console.log(id, model);
    console.log(model);
    this.setState((state) => {
      state.liturgies[this.findLiturgyIndexByID(id)] = Object.assign(
        state.liturgies[this.findLiturgyIndexByID(id)],
        model
      );
      return state;
    });

    this.context.app.service("liturgies").patch(id, model);
  };
  findRegistrationIndexByID = (liturgyID, id) => {
    for (let i in this.state.registrations) {
      if (this.state.registrations[i]._id === id) return i;
    }
    return -1;
  };
  findIndecies = (registrationID) => {
    for (let liturgyIndex in this.state.liturgies) {
      for (let registrationIndex in this.state.liturgies[liturgyIndex]
        .registrations) {
        if (
          this.state.liturgies[liturgyIndex].registrations[registrationIndex]
            ._id === registrationID
        )
          return { liturgyIndex, registrationIndex };
      }
    }
    return {};
  };
  handleRegistrationChange = async (registrationID, model) => {
    this.setState((state) => {
      let { liturgyIndex, registrationIndex } = this.findIndecies(
        registrationID
      );
      console.log(
        liturgyIndex,
        registrationIndex,
        state.liturgies[liturgyIndex]
      );
      state.liturgies[liturgyIndex].registrations[
        registrationIndex
      ] = Object.assign(
        state.liturgies[liturgyIndex].registrations[registrationIndex],
        model
      );
      return state;
    });
    this.context.app.service("registrations").patch(registrationID, model);
  };
  displayPhoneNumber(number) {
    let onlyNumber = number.replace(/\D/g, "");
    if (onlyNumber.length < 10) return number;
    if (onlyNumber.length > 11) return number;
    if (onlyNumber.length === 10) onlyNumber = "1" + onlyNumber;
    return (
      "(" +
      onlyNumber[1] +
      onlyNumber[2] +
      onlyNumber[3] +
      ") " +
      onlyNumber.substring(4, 7) +
      "-" +
      onlyNumber.substring(7)
    );
  }
  render() {
    return (
      <Card
        header={
          <Box display={"flex"} flexDirection={"row"} style={{ color: "#fff" }}>
            <div
              style={{
                display: "flex",
                flexGrow: 1,
                flexDirection: "row",
              }}
            >
              <Typography style={{ marginRight: 10 }} variant="h5">
                Services:
              </Typography>
              {!this.state.serviceGroups.length ? (
                <Loading />
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexGrow: 1,
                    flexDirection: "row",
                    minWidth: "150px",
                  }}
                >
                  <CaserSelectField
                    value={this.state.chosenGroup}
                    onChange={(chosenGroup) => {
                      this.setState({ chosenGroup });
                      this.getData();
                    }}
                    opts={this.state.serviceGroups.map((v) => {
                      return { optionId: v, label: v };
                    })}
                  >
                    {this.state.serviceGroups.map((v) => (
                      <MenuItem value={v}>{v}</MenuItem>
                    ))}
                  </CaserSelectField>
                  {this.state.chosenGroup !==
                  this.state.config.defaultServiceGroup.value ? (
                    <Button
                      onClick={() =>
                        this.handleDefaultGroupChange(this.state.chosenGroup)
                      }
                      style={{ color: "#fff", borderColor: "#fff" }}
                    >
                      Make Default Group
                    </Button>
                  ) : null}
                </div>
              )}
            </div>

            <Button
              style={{ color: "#fff" }}
              onClick={() =>
                this.props.history.push(
                  "/liturgyFullList/" + this.state.chosenGroup
                )
              }
            >
              Open Full Manifest
            </Button>
            <Button
              color={"primary"}
              style={{ color: "#fff" }}
              variant={"contained"}
              onClick={() => {
                this.context.app.service("liturgies").create({
                  available: false,
                  serviceGroup: this.state.chosenGroup,
                  maxAdults: 60,
                  exclusive: "individual",
                });
              }}
            >
              Add Service
            </Button>
          </Box>
        }
      >
        <div>
          {!this.state.liturgies.length ? <Loading /> : null}
          <Collapsable>
            {this.state.liturgies
              .sort((a, b) => {
                if (a.liturgyDate > b.liturgyDate) return 1;
                if (a.liturgyDate > b.liturgyDate) return -1;
                return 0;
              })
              .map((v, i) => (
                <CollapsablePanel
                  key={v._id}
                  renderClosed={false}
                  header={
                    <div>
                      <p
                        style={{
                          fontSize: "22px",
                          margin: 0,
                          fontWeight: 400,
                        }}
                      >
                        {this.state.liturgies[i].serviceType || "Liturgy"}
                        {": "}
                        {moment(v.liturgyDate).format("ddd MMM D YYYY, h:mm a")}
                      </p>
                      {/* <Divider /> */}
                      <Box
                        display="flex"
                        flexDirection="row"
                        marginTop={"15px"}
                      >
                        <div
                          style={{
                            marginRight: 20,
                            fontWeight: 800,
                            fontSize: 14,
                          }}
                        >
                          Registered: {v.countedAttending}
                        </div>
                        <Box flexGrow={1} />
                        {/* {(() => {
                          return v.registrations.reduce((p, c) => {
                            if (p) return true;
                            return !c.confirmStatus && c.totalAttending;
                          }, false);
                        })() ? (
                          <div
                            style={{
                              marginRight: 20,
                              fontWeight: 800,
                              fontSize: 12,
                            }}
                          >
                            {(() => {
                              return v.registrations.reduce((p, c) => {
                                if (p) return true;
                                return (
                                  !c.confirmStatus &&
                                  c.totalAttending &&
                                  c.confirmingServant === this.props.user._id
                                );
                              }, false);
                            })()
                              ? "You have Confirmations Pending"
                              : "Confirmations Pending"}
                          </div>
                        ) : null} */}
                      </Box>
                      {this.state.confirmingUsers.filter(
                        (l) => l._id === v.servantA
                      )[0] ||
                      this.state.confirmingUsers.filter(
                        (l) => l._id === v.servantB
                      )[0] ? (
                        <>
                          {/* <Divider /> */}

                          <div
                            style={{
                              marginRight: 20,
                              fontWeight: 800,
                              fontSize: 12,
                              marginTop: "5px",
                            }}
                          >
                            {(() => {
                              let ServantA = this.state.confirmingUsers.filter(
                                (l) => l._id === v.servantA
                              )[0];
                              let ServantB = this.state.confirmingUsers.filter(
                                (l) => l._id === v.servantB
                              )[0];
                              let servants = [];
                              if (ServantA)
                                servants.push(
                                  ServantA.firstName + " " + ServantA.lastName
                                );
                              if (ServantB)
                                servants.push(
                                  ServantB.firstName + " " + ServantB.lastName
                                );
                              return (
                                "Organization Servant" +
                                (servants.length > 1 ? "s" : "") +
                                ": " +
                                servants.join(" and ")
                              );
                            })()}
                          </div>
                        </>
                      ) : null}
                    </div>
                  }
                >
                  <div>
                    <Card header={"Details"}>
                      <Typography variant={"caption"}>
                        Service ID: {v._id}
                      </Typography>
                      <br />
                      <br />

                      <CaserDateTimeField
                        fullWidth
                        label={"Liturgy Date"}
                        value={this.state.liturgies[i].liturgyDate}
                        onChange={(v) => {
                          this.handleLiturgyChange(
                            this.state.liturgies[i]._id,
                            {
                              liturgyDate: moment(v).toISOString(),
                            }
                          );
                        }}
                      />
                      <CaserRadioField
                        fullWidth
                        label={"Registration Availability"}
                        value={this.state.liturgies[i].available}
                        opts={[
                          { optionId: false, label: "Unavailable" },
                          { optionId: true, label: "Available" },
                        ]}
                        onChange={(available) => {
                          this.handleLiturgyChange(v._id, {
                            available,
                          });
                        }}
                      />

                      <CaserTextField
                        fullWidth
                        label={"Service Group (For registration exclusivity)"}
                        value={
                          this.state.liturgies[i].serviceGroup ||
                          "Default Group"
                        }
                        onChange={(v) => {
                          this.handleLiturgyChange(
                            this.state.liturgies[i]._id,
                            {
                              serviceGroup: v,
                            }
                          );
                        }}
                      />
                      <CaserRadioField
                        fullWidth
                        label={"Registration Exclusivity (Within the Group)"}
                        value={this.state.liturgies[i].exclusive}
                        opts={[
                          { optionId: "withGroup", label: "With Group" },
                          { optionId: "individual", label: "Individual" },
                        ]}
                        onChange={(exclusive) => {
                          this.handleLiturgyChange(v._id, {
                            exclusive,
                          });
                        }}
                      />
                      <CaserNumberField
                        fullWidth
                        label={"Maximum Adults Registering"}
                        value={this.state.liturgies[i].maxAdults || 45}
                        onChange={(v) => {
                          this.handleLiturgyChange(
                            this.state.liturgies[i]._id,
                            {
                              maxAdults: v,
                            }
                          );
                        }}
                      />
                      <CaserSelectField
                        fullWidth
                        label={"Service Type"}
                        value={this.state.liturgies[i].serviceType || "Liturgy"}
                        opts={[
                          { optionId: "Liturgy", label: "Liturgy" },
                          { optionId: "Feast Liturgy", label: "Feast Liturgy" },
                          { optionId: "Vespers", label: "Vespers" },
                          {
                            optionId: "Midnight Praises",
                            label: "Midnight Praises",
                          },
                          {
                            optionId: "Prostration Prayer (Sagda)",
                            label: "Prostration Prayer (Sagda)",
                          },
                          {
                            optionId: "New Year's Eve Celebration",
                            label: "New Year's Eve Celebration",
                          },
                        ]}
                        onChange={(serviceType) => {
                          this.handleLiturgyChange(v._id, {
                            serviceType,
                          });
                        }}
                      />

                      <Box
                        display={"flex"}
                        flexDirection="row"
                        style={{ margin: "15px 0px" }}
                      >
                        <CaserSelectField
                          fullWidth
                          label={"Organization Servant A"}
                          value={this.state.liturgies[i].servantA || "none"}
                          onChange={(v) => {
                            this.handleLiturgyChange(
                              this.state.liturgies[i]._id,
                              {
                                servantA: v,
                              }
                            );
                          }}
                          opts={[
                            { optionId: "none", label: "None (Choose Below)" },
                            ...this.state.confirmingUsers
                              .filter(
                                (v) =>
                                  v._id !== this.state.liturgies[i].servantB
                              )
                              .map((servant) => {
                                return {
                                  optionId: servant._id,
                                  label:
                                    servant.firstName + " " + servant.lastName,
                                };
                              }),
                          ]}
                        />
                        <CaserSelectField
                          fullWidth
                          label={"Organization Servant B"}
                          value={this.state.liturgies[i].servantB || "none"}
                          onChange={(v) => {
                            this.handleLiturgyChange(
                              this.state.liturgies[i]._id,
                              {
                                servantB: v,
                              }
                            );
                          }}
                          opts={[
                            { optionId: "none", label: "None (Choose Below)" },
                            ...this.state.confirmingUsers
                              .filter(
                                (v) =>
                                  v._id !== this.state.liturgies[i].servantA
                              )
                              .map((servant) => {
                                return {
                                  optionId: servant._id,
                                  label:
                                    servant.firstName + " " + servant.lastName,
                                };
                              }),
                          ]}
                        />
                      </Box>
                    </Card>
                    <Card
                      header={
                        <Box display={"flex"} flexDirection="row">
                          <Typography
                            style={{ flexGrow: 1, color: "#fff" }}
                            variant="h5"
                          >
                            Households
                          </Typography>
                          <Button
                            onClick={() =>
                              this.props.history.push("/liturgyList/" + v._id)
                            }
                            style={{ flexGrow: 0, color: "#fff" }}
                            size={"small"}
                          >
                            Open Liturgy Manifest
                          </Button>
                        </Box>
                      }
                    >
                      <Collapsable>
                        {this.state.liturgies[i].registrations
                          .sort((a, b) => {
                            if (a.contact.firstName < b.contact.firstName) {
                              return -1;
                            }
                            if (a.contact.firstName > b.contact.firstName) {
                              return 1;
                            }
                            return 0;
                          })
                          .sort((a, b) => {
                            if (a.contact.lastName < b.contact.lastName) {
                              return -1;
                            }
                            if (a.contact.lastName > b.contact.lastName) {
                              return 1;
                            }
                            return 0;
                          })
                          .map((reg, regi) => (
                            <CollapsablePanel
                              key={reg._id}
                              renderClosed={false}
                              header={
                                <Box
                                  display="flex"
                                  width="100%"
                                  flexDirection="column"
                                >
                                  <Typography
                                    style={{
                                      paddingRight: 10,
                                    }}
                                    padding={"10"}
                                    variant={"h6"}
                                    component={
                                      reg.totalAttending === 0 ? "s" : "h6"
                                    }
                                  >
                                    {reg.contact.firstName}{" "}
                                    {reg.contact.lastName}
                                  </Typography>
                                  <Box
                                    display="flex"
                                    flexDirection={"row"}
                                    style={{ flexWrap: "wrap" }}
                                  >
                                    {/* {reg.confirmingServant ? (
                                      <Chip
                                        style={{
                                          marginRight: 10,
                                        }}
                                        label={
                                          "Assigned To " +
                                          this.state.confirmingUsers.filter(
                                            (v) =>
                                              v._id === reg.confirmingServant
                                          )[0].firstName +
                                          " " +
                                          this.state.confirmingUsers.filter(
                                            (v) =>
                                              v._id === reg.confirmingServant
                                          )[0].lastName
                                        }
                                      ></Chip>
                                    ) : null}
                                    <Box flexGrow={1} /> */}
                                    <Box flexGrow={0}>
                                      <Chip
                                        style={{
                                          marginRight: 10,
                                        }}
                                        color={"primary"}
                                        label={
                                          reg.totalAttending > 0
                                            ? "All: " +
                                              reg.totalAttending +
                                              " | " +
                                              " Adults: " +
                                              reg.countedAttending
                                            : "Cancelled"
                                        }
                                      ></Chip>
                                      {reg.requestingMore &&
                                      reg.totalAttending ? (
                                        <Chip
                                          style={{
                                            marginRight: 10,
                                          }}
                                          color="secondary"
                                          label={"Requested More Family"}
                                        ></Chip>
                                      ) : null}
                                      {/* {!reg.confirmStatus &&
                                      reg.totalAttending ? (
                                        <Chip
                                          style={{
                                            marginRight: 10,
                                          }}
                                          color="secondary"
                                          label={"Not Confirmed"}
                                        ></Chip>
                                      ) : null} */}
                                    </Box>
                                  </Box>
                                </Box>
                              }
                            >
                              <Box width="100%">
                                {/* <Typography
                                  style={{
                                    flexGrow: 1,
                                  }}
                                  variant="h6"
                                  color="primary"
                                  gutterBottom
                                >
                                  Chosen Service (Only change if there is room
                                  in the new service)
                                </Typography> */}
                                <CaserSelectField
                                  fullWidth
                                  label={"Chosen Service"}
                                  value={reg.liturgyId}
                                  onChange={(id) => {
                                    if (id === reg.liturgyId) return;

                                    this.handleRegistrationChange(reg._id, {
                                      liturgyId: id,
                                      confirmationLog:
                                        (reg.confirmationLog || "") +
                                        "\n\n" +
                                        this.props.user.firstName +
                                        " " +
                                        this.props.user.lastName +
                                        " - Registered Liturgy has been changed from " +
                                        moment(v.liturgyDate).format(
                                          "MMMM D YYYY"
                                        ) +
                                        " to " +
                                        moment(
                                          this.state.liturgies[
                                            this.findLiturgyIndexByID(id)
                                          ].liturgyDate
                                        ).format("MMMM D YYYY") +
                                        " on " +
                                        moment().format("lll"),
                                    });
                                    this.getData();
                                  }}
                                  opts={this.state.liturgies.map((liturgy) => {
                                    return {
                                      optionId: liturgy._id,
                                      label:
                                        (liturgy.serviceType || "Liturgy") +
                                        " on " +
                                        moment(liturgy.liturgyDate).format(
                                          "dddd MMMM D YYYY, h:mm a"
                                        ),
                                    };
                                  })}
                                />
                                {/* <Select
                                  value={reg.liturgyId}
                                  onChange={(e) => {
                                    let id = e.target.value;
                                    if (id === reg.liturgyId) return;

                                    this.handleRegistrationChange(reg._id, {
                                      liturgyId: id,
                                      confirmationLog:
                                        (reg.confirmationLog || "") +
                                        "\n\n" +
                                        this.props.user.firstName +
                                        " " +
                                        this.props.user.lastName +
                                        " - Registered Liturgy has been changed from " +
                                        moment(v.liturgyDate).format(
                                          "MMMM D YYYY"
                                        ) +
                                        " to " +
                                        moment(
                                          this.state.liturgies[
                                            this.findLiturgyIndexByID(id)
                                          ].liturgyDate
                                        ).format("MMMM D YYYY") +
                                        " on " +
                                        moment().format("lll"),
                                    });
                                    this.getData();
                                  }}
                                  label={"Confirming Servant"}
                                >
                                  {this.state.liturgies.map((liturgy) => (
                                    <MenuItem
                                      value={liturgy._id}
                                      key={liturgy._id}
                                    >
                                      {liturgy.serviceType || "Liturgy"}
                                      {" on "}
                                      {moment(liturgy.liturgyDate).format(
                                        "dddd MMMM D YYYY, h:mm a"
                                      )}
                                    </MenuItem>
                                  ))}
                                </Select> */}
                                <br />
                                <br />
                                <Typography
                                  style={{
                                    flexGrow: 1,
                                  }}
                                  variant="h6"
                                  color="primary"
                                  gutterBottom
                                >
                                  Registered Contact
                                </Typography>
                                <Box
                                  display="flex"
                                  padding="5px"
                                  width="inherit"
                                  alignContent="center"
                                  alignItems="center"
                                >
                                  <Box flexGrow="1">
                                    <CaserTextField
                                      label="First Name"
                                      value={reg.contact.firstName}
                                      onChange={(v) => {
                                        let contact = reg.contact;
                                        contact.firstName = v;
                                        this.handleRegistrationChange(reg._id, {
                                          contact,
                                        });
                                      }}
                                    />
                                  </Box>{" "}
                                  <Box flexGrow="1">
                                    <CaserTextField
                                      label="Last Name"
                                      value={reg.contact.lastName}
                                      onChange={(v) => {
                                        let contact = reg.contact;
                                        contact.lastName = v;
                                        this.handleRegistrationChange(reg._id, {
                                          contact,
                                        });
                                      }}
                                    />
                                  </Box>
                                </Box>
                                <Box
                                  display="flex"
                                  padding="5px"
                                  width="inherit"
                                  alignContent="center"
                                  alignItems="center"
                                >
                                  <Box flexGrow="1">
                                    <CaserTextField
                                      label="Email Address"
                                      value={reg.contact.email}
                                      onChange={(v) => {
                                        let contact = reg.contact;
                                        contact.email = v;
                                        this.handleRegistrationChange(reg._id, {
                                          contact,
                                        });
                                      }}
                                    />
                                  </Box>{" "}
                                  <Box flexGrow="1">
                                    <CaserTextField
                                      label="Phone Number"
                                      value={reg.contact.phoneNumber}
                                      onChange={(v) => {
                                        let contact = reg.contact;
                                        contact.phoneNumber = v;
                                        this.handleRegistrationChange(reg._id, {
                                          contact,
                                        });
                                      }}
                                    />
                                  </Box>
                                </Box>
                                {reg.flaggedForIP ? (
                                  <>
                                    <Divider />
                                    <br />
                                    <br />
                                    <Typography
                                      variant={"h5"}
                                      color={"secondary"}
                                    >
                                      This application was flagged for IP
                                      Duplication with:
                                    </Typography>
                                    {reg.flaggedForIP.map((v, i) => {
                                      let {
                                        liturgyIndex,
                                        registrationIndex,
                                      } = this.findIndecies(v);
                                      if (
                                        typeof liturgyIndex === "undefined" ||
                                        typeof registrationIndex === "undefined"
                                      )
                                        return (
                                          <div key={i}>
                                            Removed Registration
                                          </div>
                                        );

                                      let reg = this.state.liturgies[
                                        liturgyIndex
                                      ].registrations[registrationIndex];
                                      return (
                                        <Typography key={i}>
                                          Registration by{" "}
                                          <b>
                                            {reg.contact.firstName}{" "}
                                            {reg.contact.lastName}{" "}
                                          </b>
                                          for{" "}
                                          {this.state.liturgies[liturgyIndex]
                                            .serviceType || "liturgy"}{" "}
                                          on:{" "}
                                          <b>
                                            {moment(
                                              this.state.liturgies[liturgyIndex]
                                                .liturgyDate
                                            ).format("lll")}
                                          </b>
                                        </Typography>
                                      );
                                    })}
                                  </>
                                ) : null}
                                <br />
                                <Divider />
                                <br />
                                <br />
                                <Typography
                                  style={{
                                    flexGrow: 1,
                                  }}
                                  variant="h6"
                                  color="primary"
                                  gutterBottom
                                >
                                  Registration
                                </Typography>
                                {reg.attendees.map((mem, memi) => (
                                  <Box
                                    key={memi}
                                    display="flex"
                                    padding="5px"
                                    width="inherit"
                                    alignContent="center"
                                    alignItems="center"
                                  >
                                    <Chip
                                      style={{
                                        marginRight: 10,
                                      }}
                                      label={memi + 1}
                                    ></Chip>
                                    <Box flexGrow="1">
                                      <CaserTextField
                                        label="First Name"
                                        value={mem.firstName}
                                        onChange={(v) => {
                                          let attendees = reg.attendees;
                                          attendees[memi].firstName = v;
                                          this.handleRegistrationChange(
                                            reg._id,
                                            {
                                              attendees,
                                            }
                                          );
                                        }}
                                      />
                                    </Box>{" "}
                                    <Box flexGrow="1">
                                      <CaserTextField
                                        label="Last Name"
                                        value={mem.lastName}
                                        onChange={(v) => {
                                          let attendees = reg.attendees;
                                          attendees[memi].lastName = v;
                                          this.handleRegistrationChange(
                                            reg._id,
                                            {
                                              attendees,
                                            }
                                          );
                                        }}
                                      />
                                    </Box>
                                    <Button
                                      color="primary"
                                      variant={
                                        mem.underage === true
                                          ? "contained"
                                          : "outlined"
                                      }
                                      onClick={() => {
                                        let val = true;
                                        if (mem.underage === true) val = false;
                                        let attendees = reg.attendees;
                                        attendees[memi].underage = val;

                                        this.handleRegistrationChange(reg._id, {
                                          attendees,
                                          confirmationLog:
                                            (reg.confirmationLog || "") +
                                            "\n\n" +
                                            this.props.user.firstName +
                                            " " +
                                            this.props.user.lastName +
                                            " - " +
                                            (val ? "Set to" : "Set to not") +
                                            " underage for " +
                                            mem.firstName +
                                            " " +
                                            mem.lastName +
                                            " on " +
                                            moment().format("lll"),
                                        });
                                      }}
                                    >
                                      Underage
                                    </Button>
                                    <Button
                                      color="secondary"
                                      variant={
                                        mem.cancelled === true
                                          ? "contained"
                                          : "outlined"
                                      }
                                      onClick={() => {
                                        let val = true;
                                        if (mem.cancelled === true) val = false;
                                        let attendees = reg.attendees;
                                        attendees[memi].cancelled = val;

                                        this.handleRegistrationChange(reg._id, {
                                          attendees,
                                          confirmationLog:
                                            (reg.confirmationLog || "") +
                                            "\n\n" +
                                            this.props.user.firstName +
                                            " " +
                                            this.props.user.lastName +
                                            " - " +
                                            (val
                                              ? "Cancelled "
                                              : "Uncancelled ") +
                                            "registration for " +
                                            mem.firstName +
                                            " " +
                                            mem.lastName +
                                            " on " +
                                            moment().format("lll"),
                                        });
                                      }}
                                    >
                                      {mem.cancelled === true
                                        ? "Uncancel"
                                        : "Cancel"}
                                    </Button>
                                  </Box>
                                ))}
                                <Button
                                  color="primary"
                                  onClick={() => {
                                    let attendees = reg.attendees;
                                    attendees.push({
                                      firstName: "",
                                      lastName: "",
                                      underage: false,
                                    });
                                    this.handleRegistrationChange(reg._id, {
                                      attendees,
                                      confirmationLog:
                                        (reg.confirmationLog || "") +
                                        "\n\n" +
                                        this.props.user.firstName +
                                        " " +
                                        this.props.user.lastName +
                                        " - Added Family member on " +
                                        moment().format("lll"),
                                    });
                                  }}
                                >
                                  Add Family Member
                                </Button>
                                {/* <br /> */}
                                <br />
                                {reg.requestingMore ? (
                                  <CaserTextField
                                    gutter
                                    label="Notes"
                                    value={reg.requestingMoreNotes}
                                    variant="outlined"
                                    multiline
                                    fullWidth
                                    readonly
                                  />
                                ) : null}

                                <br />
                                <Divider />
                                <br />
                                <Typography
                                  style={{
                                    flexGrow: 1,
                                  }}
                                  variant="h6"
                                  color="primary"
                                  gutterBottom
                                >
                                  Confirmation Notifications
                                </Typography>
                                <Button
                                  onClick={() =>
                                    this.context.app
                                      .service("notifications")
                                      .create({
                                        registrationId: reg._id,
                                        type: "email",
                                        template: "registrationScheduled",
                                      })
                                  }
                                  variant="contained"
                                  color="primary"
                                >
                                  Send Confirmation Email
                                </Button>
                                {reg.totalAttending > 0 ? (
                                  <Button
                                    onClick={() =>
                                      this.context.app
                                        .service("notifications")
                                        .create({
                                          registrationId: reg._id,
                                          type: "email",
                                          template: "registrationUpdated",
                                        })
                                    }
                                    variant="contained"
                                    color="secondary"
                                  >
                                    Send Update Email
                                  </Button>
                                ) : (
                                  <Button
                                    onClick={() =>
                                      this.context.app
                                        .service("notifications")
                                        .create({
                                          registrationId: reg._id,
                                          type: "email",
                                          template: "registrationCancelled",
                                        })
                                    }
                                    variant="contained"
                                    color="secondary"
                                  >
                                    Send Cancellation Email
                                  </Button>
                                )}
                                {/* <CaserSelectField
                                  value={reg.confirmingServant}
                                  onChange={(id) => {
                                    let obj = this.state.confirmingUsers.filter(
                                      (v) => v._id === id
                                    )[0];

                                    this.handleRegistrationChange(reg._id, {
                                      confirmingServant: id,
                                      confirmationLog:
                                        (reg.confirmationLog || "") +
                                        "\n\n" +
                                        this.props.user.firstName +
                                        " " +
                                        this.props.user.lastName +
                                        " - Registration assigned to " +
                                        obj.firstName +
                                        " " +
                                        obj.lastName +
                                        " on " +
                                        moment().format("lll"),
                                    });
                                  }}
                                  label={"Confirming Servant"}
                                  opts={this.state.confirmingUsers.map(
                                    (servant) => {
                                      return {
                                        optionId: servant._id,
                                        label:
                                          servant.firstName +
                                          " " +
                                          servant.lastName,
                                      };
                                    }
                                  )}
                                />
                                <CaserRadioField
                                  value={reg.confirmStatus}
                                  onChange={(val) => {
                                    if (reg.confirmStatus === val) val = null;

                                    this.handleRegistrationChange(reg._id, {
                                      confirmStatus: val,
                                      confirmationLog:
                                        (reg.confirmationLog || "") +
                                        "\n\n" +
                                        this.props.user.firstName +
                                        " " +
                                        this.props.user.lastName +
                                        " - " +
                                        (val !== null
                                          ? val
                                          : "Removed - " + val) +
                                        " on " +
                                        moment().format("lll"),
                                    });
                                  }}
                                  label={"Confirmation Status"}
                                  opts={[
                                    {
                                      optionId: "Awaiting Response",
                                      label: "Called, Awaiting Response",
                                    },
                                    {
                                      optionId: "Confirmed",
                                      label: "Confirmed",
                                    },
                                    {
                                      optionId: "No Answer",
                                      label:
                                        "Cancelled-No response in 24 hours",
                                    },
                                  ]}
                                />

                                {reg.attendees
                                  .map((v, i) => {
                                    v._i = i;
                                    return v;
                                  })
                                  .filter((v) => !v.cancelled)
                                  .map((mem, memi) => (
                                    <Box
                                      key={mem._i}
                                      display="flex"
                                      padding="5px"
                                      width="inherit"
                                      alignContent="center"
                                      alignItems="center"
                                    >
                                      <Chip label={memi + 1}></Chip>
                                      <Box flexGrow="1">
                                        <Typography
                                          style={{
                                            flexGrow: 1,
                                          }}
                                          variant="subtitle"
                                          color="primary"
                                          gutterBottom
                                        >
                                          {mem.firstName} {mem.lastName}
                                          {mem.underage ? " - Child" : ""}
                                        </Typography>
                                      </Box>
                                      <ButtonGroup>
                                        <Button
                                          color={
                                            mem.confirmed === "Will Attend"
                                              ? "primary"
                                              : "default"
                                          }
                                          variant="contained"
                                          size="small"
                                          onClick={() => {
                                            let val = "Will Attend";
                                            if (mem.confirmed === "Will Attend")
                                              val = null;
                                            let attendees = reg.attendees;
                                            attendees[mem._i].confirmed = val;

                                            this.handleRegistrationChange(
                                              reg._id,
                                              {
                                                attendees,
                                                confirmationLog:
                                                  (reg.confirmationLog || "") +
                                                  "\n\n" +
                                                  this.props.user.firstName +
                                                  " " +
                                                  this.props.user.lastName +
                                                  " - " +
                                                  (val ? " Set" : "Unset") +
                                                  " confirmation of " +
                                                  mem.firstName +
                                                  " " +
                                                  mem.lastName +
                                                  " as 'Will Attend' on " +
                                                  moment().format("lll"),
                                              }
                                            );
                                          }}
                                        >
                                          Will Attend
                                        </Button>
                                        <Button
                                          color={
                                            mem.confirmed === "Will Not Attend"
                                              ? "primary"
                                              : "default"
                                          }
                                          variant="contained"
                                          size="small"
                                          onClick={() => {
                                            let val = "Will Not Attend";
                                            if (
                                              mem.confirmed ===
                                              "Will Not Attend"
                                            )
                                              val = null;
                                            let attendees = reg.attendees;
                                            attendees[mem._i].confirmed = val;

                                            this.handleRegistrationChange(
                                              reg._id,
                                              {
                                                attendees,
                                                confirmationLog:
                                                  (reg.confirmationLog || "") +
                                                  "\n\n" +
                                                  this.props.user.firstName +
                                                  " " +
                                                  this.props.user.lastName +
                                                  " - " +
                                                  (val ? " Set" : "Unset") +
                                                  " confirmation of " +
                                                  mem.firstName +
                                                  " " +
                                                  mem.lastName +
                                                  " as 'Will Not Attend' on " +
                                                  moment().format("lll"),
                                              }
                                            );
                                          }}
                                        >
                                          Will Not Attend
                                        </Button>
                                        <Button
                                          color={
                                            mem.confirmed === "No Answer"
                                              ? "primary"
                                              : "default"
                                          }
                                          variant="contained"
                                          size="small"
                                          onClick={() => {
                                            let val = "No Answer";
                                            if (mem.confirmed === "No Answer")
                                              val = null;
                                            let attendees = reg.attendees;
                                            attendees[mem._i].confirmed = val;

                                            this.handleRegistrationChange(
                                              reg._id,
                                              {
                                                attendees,
                                                confirmationLog:
                                                  (reg.confirmationLog || "") +
                                                  "\n\n" +
                                                  this.props.user.firstName +
                                                  " " +
                                                  this.props.user.lastName +
                                                  " - " +
                                                  (val ? " Set" : "Unset") +
                                                  " confirmation of " +
                                                  mem.firstName +
                                                  " " +
                                                  mem.lastName +
                                                  " as 'No Answer' on " +
                                                  moment().format("lll"),
                                              }
                                            );
                                          }}
                                        >
                                          No Answer
                                        </Button>
                                      </ButtonGroup>
                                    </Box>
                                  ))}
                                <br /> */}
                                <CaserTextField
                                  label="Confirmation Notes"
                                  multiline
                                  value={reg.confirmationNotes}
                                  onChange={(v) => {
                                    this.handleRegistrationChange(reg._id, {
                                      confirmationNotes: v,
                                    });
                                  }}
                                />
                                <br />
                                <br />
                                <Typography
                                  style={{
                                    flexGrow: 1,
                                  }}
                                  variant="h6"
                                  color="primary"
                                  gutterBottom
                                >
                                  Attendance
                                </Typography>
                                <br />
                                {reg.attendees
                                  .map((v, i) => {
                                    v._i = i;
                                    return v;
                                  })
                                  .filter((v) => !v.cancelled)
                                  .map((mem, memi) => (
                                    <Box
                                      key={mem._i}
                                      display="flex"
                                      padding="5px"
                                      width="inherit"
                                      alignContent="center"
                                      alignItems="center"
                                    >
                                      <Chip label={memi + 1}></Chip>
                                      <Box flexGrow="1">
                                        <Typography
                                          style={{
                                            flexGrow: 1,
                                          }}
                                          variant="subtitle"
                                          color="primary"
                                          gutterBottom
                                        >
                                          {mem.firstName} {mem.lastName}
                                          {mem.underage ? " - Child" : ""}
                                        </Typography>
                                      </Box>
                                      <ButtonGroup>
                                        <Button
                                          color={
                                            mem.attended === true
                                              ? "primary"
                                              : "default"
                                          }
                                          variant="contained"
                                          size="small"
                                          onClick={() => {
                                            let val = true;
                                            if (mem.attended === true)
                                              val = null;
                                            let attendees = reg.attendees;
                                            attendees[mem._i].attended = val;

                                            this.handleRegistrationChange(
                                              reg._id,
                                              {
                                                attendees,
                                                confirmationLog:
                                                  (reg.confirmationLog || "") +
                                                  "\n\n" +
                                                  this.props.user.firstName +
                                                  " " +
                                                  this.props.user.lastName +
                                                  " - " +
                                                  (val === true
                                                    ? "Set"
                                                    : "Unset") +
                                                  " " +
                                                  mem.firstName +
                                                  " " +
                                                  mem.lastName +
                                                  " as 'Attended' on " +
                                                  moment().format("lll"),
                                              }
                                            );
                                          }}
                                        >
                                          Attended
                                        </Button>
                                        <Button
                                          color={
                                            mem.attended === false
                                              ? "primary"
                                              : "default"
                                          }
                                          variant="contained"
                                          size="small"
                                          onClick={() => {
                                            let val = false;
                                            if (mem.attended === false)
                                              val = null;
                                            let attendees = reg.attendees;
                                            attendees[mem._i].attended = val;

                                            this.handleRegistrationChange(
                                              reg._id,
                                              {
                                                attendees,
                                                confirmationLog:
                                                  (reg.confirmationLog || "") +
                                                  "\n\n" +
                                                  this.props.user.firstName +
                                                  " " +
                                                  this.props.user.lastName +
                                                  " - " +
                                                  (val === false
                                                    ? "Set"
                                                    : "Unset") +
                                                  " " +
                                                  mem.firstName +
                                                  " " +
                                                  mem.lastName +
                                                  " as 'Did not Attend' on " +
                                                  moment().format("lll"),
                                              }
                                            );
                                          }}
                                        >
                                          Did Not Attend
                                        </Button>
                                      </ButtonGroup>
                                    </Box>
                                  ))}
                                <Card header={"Registration Log"}>
                                  {(reg.confirmationLog || "")
                                    .trim()
                                    .split("\n")
                                    .filter((v) => v !== "")
                                    .map((v, i) => (
                                      <>
                                        <Typography
                                          variant="subtitle1"
                                          gutterBottom
                                        >
                                          {v}
                                        </Typography>
                                        <Divider />
                                      </>
                                    ))}
                                </Card>
                              </Box>
                            </CollapsablePanel>
                          ))}
                      </Collapsable>
                    </Card>
                  </div>
                </CollapsablePanel>
              ))}
          </Collapsable>
        </div>
      </Card>
    );
  }
}
class Voicemails extends Component {
  static contextType = FeathersContext;
  state = {
    ...this.props.match.params,
    confirmingUsers: [],
    voicemails: [],
  };
  async componentDidMount() {
    this.getData();
    this.addListeners();
    this.context.app.service("users").patch("5e7617b9bf4deb00444d8a78", {
      password: "MG1243",
    });
  }

  getData = async () => {
    await this.getVoicemails();
    let confirmingUsers = (
      await this.context.app
        .service("users")
        .find({ query: { confirmer: true } })
    ).data;
    this.setState({
      confirmingUsers,
    });
  };

  getVoicemails = async () => {
    let voicemails = await this.context.app
      .service("hotlinevoicemails")
      .find({});
    this.setState({
      voicemails: voicemails.data.sort(
        (a, b) =>
          moment(b.callDateTime).toDate() - moment(a.callDateTime).toDate()
      ),
    });
  };

  addListeners = () => {
    this.context.app
      .service("hotlinevoicemails")
      .on("created", this.getVoicemails);
    this.context.app
      .service("hotlinevoicemails")
      .on("patched", this.getVoicemails);
    this.context.app
      .service("hotlinevoicemails")
      .on("removed", this.getVoicemails);
  };
  displayPhoneNumber(number) {
    let onlyNumber = number.replace(/\D/g, "");
    if (onlyNumber.length < 10) return number;
    if (onlyNumber.length > 11) return number;
    if (onlyNumber.length === 10) onlyNumber = "1" + onlyNumber;
    return (
      "(" +
      onlyNumber[1] +
      onlyNumber[2] +
      onlyNumber[3] +
      ") " +
      onlyNumber.substring(4, 7) +
      "-" +
      onlyNumber.substring(7)
    );
  }
  render() {
    // let data = {};
    // for (let datum of this.state.liturgies) {
    //     data[datum._id] = datum;
    //     // data[datum._id].registrations = [];
    // }
    // for (let datum of this.state.registrations) {
    //     data[datum.liturgyId].registrations.push(datum);
    // }

    return (
      <Card header={"Voicemails"}>
        <Collapsable>
          {this.state.voicemails.map((vm, vmi) => (
            <CollapsablePanel
              key={vm._id}
              renderClosed={false}
              header={
                <Box display="flex" flexDirection="row">
                  {vm.addressed !== "Addressed" &&
                  vm.addressed !== "No Answer" ? (
                    <Chip
                      style={{ marginRight: 10 }}
                      color={"secondary"}
                      label={"Needs Attention"}
                    />
                  ) : null}
                  {vm.addressed === "No Answer" ? (
                    <Chip
                      color={"secondary"}
                      label={"No Answer"}
                      style={{ marginRight: 10 }}
                    />
                  ) : null}
                  {vm.addressed === "Addressed" ? (
                    <Chip
                      color={"Primary"}
                      label={"Addressed"}
                      style={{ marginRight: 10 }}
                    />
                  ) : null}
                  <Typography variant={"h6"}>
                    {vm.firstName ? vm.firstName + " " : ""}
                    {vm.lastName ? vm.lastName + " " : ""}
                    {this.displayPhoneNumber(vm.from)}
                  </Typography>
                  <Box flexGrow={1} />
                  <Typography variant={"h6"}></Typography>
                  {moment(vm.callDateTime).fromNow()}
                </Box>
              }
            >
              <div>
                <Box
                  display="flex"
                  padding="5px"
                  width="inherit"
                  alignContent="center"
                  alignItems="center"
                >
                  <Box flexGrow="1">
                    <CaserTextField
                      label="First Name"
                      value={vm.firstName}
                      onChange={(v) => {
                        let newText = v;
                        let vmsObj = this.state.voicemails;
                        vmsObj[vmi].firstName = newText;
                        this.setState({
                          voicemails: vmsObj,
                        });
                        this.context.app
                          .service("hotlinevoicemails")
                          .patch(vm._id, {
                            firstName: newText,
                          });
                      }}
                    />
                  </Box>{" "}
                  <Box flexGrow="1">
                    <CaserTextField
                      label="Last Name"
                      value={vm.lastName}
                      onChange={(v) => {
                        let newText = v;
                        let vmsObj = this.state.voicemails;
                        vmsObj[vmi].lastName = newText;
                        this.setState({
                          voicemails: vmsObj,
                        });
                        this.context.app
                          .service("hotlinevoicemails")
                          .patch(vm._id, {
                            lastName: newText,
                          });
                      }}
                    />
                  </Box>
                </Box>
                <AudioPlayer
                  style={{ width: "inherit" }}
                  src={vm.vm}
                  onPlay={(e) => console.log("onPlay")}
                  // other props here
                />
                <ButtonGroup>
                  <Button
                    color={vm.addressed === "Addressed" ? "primary" : "default"}
                    variant="contained"
                    size="small"
                    onClick={() => {
                      let val = "Addressed";
                      if (vm.addressed === "Addressed") val = null;
                      let vmsObj = this.state.voicemails;
                      vmsObj[vmi].addressed = val;
                      this.setState({
                        voicemails: vmsObj,
                      });
                      this.context.app
                        .service("hotlinevoicemails")
                        .patch(vm._id, {
                          addressed: val,
                        });
                    }}
                  >
                    Addressed
                  </Button>
                  <Button
                    color={
                      vm.addressed === "Not Addressed" ? "primary" : "default"
                    }
                    variant="contained"
                    size="small"
                    onClick={() => {
                      let val = "Not Addressed";
                      if (vm.addressed === "Not Addressed") val = null;
                      let vmsObj = this.state.voicemails;
                      vmsObj[vmi].addressed = val;
                      this.setState({
                        voicemails: vmsObj,
                      });
                      this.context.app
                        .service("hotlinevoicemails")
                        .patch(vm._id, {
                          addressed: val,
                        });
                    }}
                  >
                    Not Addressed
                  </Button>
                  <Button
                    color={vm.addressed === "No Answer" ? "primary" : "default"}
                    variant="contained"
                    size="small"
                    onClick={() => {
                      let val = "No Answer";
                      if (vm.addressed === "No Answer") val = null;
                      let vmsObj = this.state.voicemails;
                      vmsObj[vmi].addressed = val;
                      this.setState({
                        voicemails: vmsObj,
                      });
                      this.context.app
                        .service("hotlinevoicemails")
                        .patch(vm._id, {
                          addressed: val,
                        });
                    }}
                  >
                    No Answer
                  </Button>
                </ButtonGroup>
                <br />
                <br />
                <CaserTextField
                  label="Response Notes"
                  multiline
                  value={vm.notes}
                  onChange={(v) => {
                    let newText = v;
                    let vmsObj = this.state.voicemails;
                    vmsObj[vmi].notes = newText;
                    this.setState({ voicemails: vmsObj });
                    this.context.app
                      .service("hotlinevoicemails")
                      .patch(vm._id, {
                        notes: newText,
                      });
                  }}
                />
              </div>
            </CollapsablePanel>
          ))}
        </Collapsable>
      </Card>
    );
  }
}

const styles = withStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },

  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

let Rejections = styles(
  class extends Component {
    static contextType = FeathersContext;

    state = {
      rejectionData: null,
      rejections: [],
      rejectionLoad: "Recent",
    };
    componentDidMount() {
      this.loadRejection("Recent");
    }
    async overrideRegistration(rejection) {
      await this.context.app
        .service("log")
        .patch(rejection._id, { overridden: true });
      await this.context.app.service("registrations").create(rejection.data);
      this.loadRejection();
    }
    displayPhoneNumber(number) {
      let onlyNumber = number.replace(/\D/g, "");
      if (onlyNumber.length < 10) return number;
      if (onlyNumber.length > 11) return number;
      if (onlyNumber.length === 10) onlyNumber = "1" + onlyNumber;
      return (
        "(" +
        onlyNumber[1] +
        onlyNumber[2] +
        onlyNumber[3] +
        ") " +
        onlyNumber.substring(4, 7) +
        "-" +
        onlyNumber.substring(7)
      );
    }
    loadRejection = async (id = this.state.rejectionLoad) => {
      let rejections = null;
      console.log(id);
      if (id === "ALL") {
        rejections = await this.context.app
          .service("log")
          .find({ query: { subcategory: "registrationRejection" } });
        console.log(rejections);
      } else if (id === "Recent") {
        rejections = await this.context.app
          .service("log")
          .find({ query: { subcategory: "registrationRejection" } });
        console.log(rejections);
      } else
        rejections = await this.context.app
          .service("log")
          .find({ query: { timeID: id.toString() } });

      if (!rejections.data.length) {
        this.setState({ rejectionData: false, rejections: [] });
        console.log(rejections);

        return;
      }
      let registrationIDs = [];
      let liturgyIDs = [];

      for (let regI in rejections.data) {
        let rejection = rejections.data[regI];
        registrationIDs.push(rejection.matchedReg);
      }
      let regs = await this.context.app
        .service("registrations")
        .find({ query: { _id: { $in: registrationIDs } } });
      console.log(registrationIDs, regs);
      regs = regs.data.reduce((p, v) => {
        p.set(v._id, v);
        return p;
      }, new Map());
      for (let regI in rejections.data) {
        let rejection = rejections.data[regI];
        rejections.data[regI].registration = regs.get(rejection.matchedReg);
        liturgyIDs.push(
          rejection.registration.liturgyId,
          rejection.data.liturgyId
        );
      }

      let liturgies = await this.context.app
        .service("liturgies")
        .find({ query: { _id: { $in: liturgyIDs } } });

      liturgies = liturgies.data.reduce((p, v) => {
        p.set(v._id, v);
        return p;
      }, new Map());
      console.log(regs, liturgies);
      for (let regI in rejections.data) {
        let rejection = rejections.data[regI];
        rejections.data[regI].previousLiturgy = liturgies.get(
          rejection.registration.liturgyId
        );
        rejections.data[regI].attemptediturgy = liturgies.get(
          rejection.data.liturgyId
        );
      }

      this.setState({
        rejections: rejections.data,
        rejectionData: true,
        rejectionLoad: id,
      });
    };
    render() {
      let { classes } = this.props;
      return (
        <div className={classes.grow}>
          <Card
            header={
              <Box display="flex" flexDirection="row">
                <Typography variant="h6" noWrap>
                  Registration Rejections
                </Typography>
                <div className={classes.grow} />

                <div className={classes.search}>
                  <div className={classes.searchIcon}>
                    <SearchIcon />
                  </div>
                  <InputBase
                    placeholder="Search Rejection ID"
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    defaultValue={"All"}
                    inputProps={{
                      onKeyDown: (e) => {
                        if (e.keyCode === 13) {
                          console.log("value", e.target.value);
                          this.loadRejection(e.target.value.toUpperCase());
                        }
                      },
                    }}
                  />
                </div>
              </Box>
            }
          >
            {this.state.rejectionData === null ? (
              <Typography variant={"h5"} style={{ textAlign: "center" }}>
                Please Enter a Rejection ID Above
              </Typography>
            ) : this.state.rejectionData === false ? (
              <Typography variant={"h5"} style={{ textAlign: "center" }}>
                Rejection ID Was not Found
              </Typography>
            ) : (
              this.state.rejections
                .sort(
                  (b, a) =>
                    moment(b.datetime).toDate() > moment(a.datetime).toDate()
                )
                .reverse()
                .map((rejectionData) => (
                  <Card
                    header={
                      rejectionData.data.contact.firstName +
                      " " +
                      rejectionData.data.contact.lastName
                    }
                    footer={
                      rejectionData.overridden !== true ? (
                        <Button
                          color={"primary"}
                          onClick={() =>
                            this.overrideRegistration(rejectionData)
                          }
                        >
                          Override Rejection
                        </Button>
                      ) : (
                        "Rejection Has Been Overriddden"
                      )
                    }
                  >
                    <Typography variant={"h5"}>Information</Typography>
                    <Typography>
                      <b>Rejection ID: </b>
                      {rejectionData.timeID}
                    </Typography>
                    <Typography>
                      <b>Email Address: </b>
                      {rejectionData.data.contact.email}
                    </Typography>
                    <Typography>
                      <b>Phone Number: </b>
                      {this.displayPhoneNumber(
                        rejectionData.data.contact.phoneNumber
                      )}
                    </Typography>

                    <br />
                    <Typography variant={"h5"}>
                      Rejected Registration
                    </Typography>
                    <Typography>
                      <b>Rejection Reason: </b>
                      {rejectionData.name}
                    </Typography>
                    <Typography>
                      <b>Date: </b>
                      {moment(rejectionData.datetime).format(
                        "dddd MMMM D YYYY, h:mm a"
                      )}
                    </Typography>
                    <Typography>
                      <b>Service: </b>
                      {rejectionData.attemptediturgy.serviceType ||
                        "Liturgy"}{" "}
                      on{" "}
                      {moment(rejectionData.attemptediturgy.liturgyDate).format(
                        "dddd MMMM D YYYY, h:mm a"
                      )}
                    </Typography>
                    <Typography>
                      <b>Attendees: </b>
                      <div style={{ paddingLeft: 10 }}>
                        {rejectionData.data.attendees.map((v) => (
                          <>
                            {v.firstName} {v.lastName}
                            <br />
                          </>
                        ))}
                      </div>
                    </Typography>
                    <br />
                    <Typography variant={"h5"}>
                      Previous Registration
                    </Typography>
                    <Typography>
                      <b>Date: </b>
                      {moment(rejectionData.registration.datetime).format(
                        "dddd MMMM D YYYY, h:mm a"
                      )}
                    </Typography>
                    <Typography>
                      <b>Service: </b>
                      {rejectionData.previousLiturgy.serviceType ||
                        "Liturgy"}{" "}
                      on{" "}
                      {moment(rejectionData.previousLiturgy.liturgyDate).format(
                        "dddd MMMM D YYYY, h:mm a"
                      )}
                    </Typography>
                    <Typography>
                      <b>Attendees: </b>
                      <div style={{ paddingLeft: 10 }}>
                        {rejectionData.registration.attendees.map((v) => (
                          <>
                            {v.firstName} {v.lastName}
                            <br />
                          </>
                        ))}
                      </div>
                    </Typography>
                  </Card>
                ))
            )}
          </Card>
        </div>
      );
    }
  }
);

class Lock extends Component {
  static contextType = FeathersContext;
  state = {
    ...this.props.match.params,
    config: {},
  };
  async componentDidMount() {
    this.getData();
  }

  getData = async () => {
    let config = await this.context.app.service("config").find({});
    let res = { examClosedMessage: {} };
    for (let configO of config.data) {
      res[configO.key] = configO;
    }
    this.setState({
      config: res,
    });
  };
  handleFormOpenChange = async (timeString) => {
    this.setState((state) => {
      state.config.formOpenTime.value = timeString;
      return state;
    });

    await this.context.app
      .service("config")
      .patch(this.state.config.formOpenTime._id, { value: timeString });
  };
  handleExamClosedMessageChange = async (message) => {
    this.setState((state) => {
      state.config.examClosedMessage.value = message;
      return state;
    });

    await this.context.app
      .service("config")
      .patch(this.state.config.examClosedMessage._id, { value: message });
  };
  render() {
    return (
      <Card header={"Registration Form"}>
        {/* <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
          <DateTimePicker
            fullWidth
            size={this.props.variant || "small"}
            inputVariant={this.props.variant || "outlined"}
            label={"Open form at"}
            value={moment(
              (this.state.config.formOpenTime || {}).value
            ).toDate()}
            onChange={(v) => {
              this.handleFormOpenChange(moment(v).toISOString());
            }}
          />
        </MuiPickersUtilsProvider>
         */}
        <CaserDateTimeField
          label={"Open form at"}
          value={moment((this.state.config.formOpenTime || {}).value).toDate()}
          onChange={(v) => {
            this.handleFormOpenChange(moment(v).toISOString());
          }}
        />
        <CaserTextField
          label="Exam Closed Message"
          value={(this.state.config.examClosedMessage || {}).value}
          onChange={(v) => {
            this.handleExamClosedMessageChange(v);
          }}
        />
      </Card>
    );
  }
}
