import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  NavLink,
} from "react-router-dom";
import "./components/mainComponents/App.css";
import "./App.css";
import feathers from "@feathersjs/client";
import io from "socket.io-client";
import FeathersContext from "./feathersContext";
import Auth from "./components/Auth.jsx";
import OCL from "./pages/ocl/main";
import UserDashboard from "./pages/dashboard/main.jsx";
import ElectionDashboard from "./pages/electionDashboard/main.jsx";
import LiturgyProtectedAttendanceList from "./pages/dashboard/LiturgyProtectedAttendanceList";
import LiturgyAttendanceList from "./pages/dashboard/LiturgyAttendanceList";
import LiturgyFullAttendanceList from "./pages/dashboard/LiturgyFullAttendanceList";
import Register from "./pages/register/main";
import Signup from "./pages/signup/main";
import EULA from "./pages/legal/eula";
import PrivacyPolicy from "./pages/legal/pp";
import TextDashboard from "./pages/textDashboard/main";
import ZoomInstructions from "./pages/publicSite/ZoomInstructions";

import { Box, Button } from "@material-ui/core";

let socket = io("https://saintmarydelray.com");
// let socket = io("http://localhost:8080");
// @feathersjs/client is exposed as the `feathers` global.
const app = feathers();

app.configure(
  feathers.socketio(socket, {
    timeout: 50000,
  })
);
app.configure(feathers.authentication());

class App extends React.Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route
            path="/text"
            render={({ match }) => {
              return <OCL match={match} />;
            }}
          ></Route>
          <Route
            render={({ match, history }) => {
              return (
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    height: "100%",
                    flexDirection: "column",
                  }}
                >
                  <div className={"mainToolbar"}>
                    St. Mary Coptic Orthodox Church
                    <br />
                    Delray Beach, FL
                    <Box flexGrow={1}> </Box>
                    <div className="navlinks">
                      <NavLink exact to="/">
                        Register
                      </NavLink>
                      <NavLink to="/text">Text</NavLink>
                      <NavLink to="/zoomInstructions">Meeting</NavLink>
                    </div>
                  </div>
                  <div
                    id={"mainContent"}
                    style={{
                      flexGrow: 1,
                      width: "100%",
                      minHeight: "1px",
                      padding: "5px",
                      overflowY: "scroll",
                      boxSizing: "border-box",
                    }}
                  >
                    <FeathersContext.Provider
                      value={{
                        app,
                        feathers,
                      }}
                    >
                      <Switch>
                        <Route
                          path="/EULA"
                          render={({ match }) => {
                            return <EULA match={match} />;
                          }}
                        ></Route>
                        <Route
                          path="/privacypolicy"
                          render={({ match }) => {
                            return <PrivacyPolicy match={match} />;
                          }}
                        ></Route>
                        <Route
                          path="/signup"
                          render={({ match }) => {
                            return (
                              <Auth>
                                <Signup match={match} />
                              </Auth>
                            );
                          }}
                        ></Route>
                        <Route
                          path="/textDashboard"
                          render={({ match }) => {
                            return (
                              <Auth>
                                <TextDashboard match={match} />
                              </Auth>
                            );
                          }}
                        ></Route>
                        <Route
                          path="/admin"
                          render={({ match, history }) => {
                            return (
                              <Auth>
                                <UserDashboard
                                  match={match}
                                  history={history}
                                />
                              </Auth>
                            );
                          }}
                        ></Route>
                        <Route
                          path="/priestVotingDashboard"
                          render={({ match, history }) => {
                            return (
                              <Auth>
                                <ElectionDashboard
                                  match={match}
                                  history={history}
                                />
                              </Auth>
                            );
                          }}
                        ></Route>
                        <Route
                          path="/election"
                          render={() => {
                            return (
                              <div>
                                <iframe
                                  src="https://docs.google.com/forms/d/e/1FAIpQLSfmEKD-ufm4JtT9jB3WeQmfdNIfd4agBFFwPEAzs0Hv5dO5rA/viewform?embedded=true"
                                  width="100%"
                                  height="1390"
                                  frameborder="0"
                                  marginheight="0"
                                  marginwidth="0"
                                >
                                  Loading…
                                </iframe>
                              </div>
                            );
                          }}
                        ></Route>
                        <Route
                          path="/litugyList/:liturgyId"
                          render={({ match }) => {
                            return <LiturgyAttendanceList match={match} />;
                          }}
                        ></Route>
                        <Route
                          path="/litugyProtectedList/:liturgyId"
                          render={({ match }) => {
                            return (
                              <Auth>
                                <LiturgyProtectedAttendanceList match={match} />
                              </Auth>
                            );
                          }}
                        ></Route>
                        <Route
                          path="/liturgyList/:liturgyId"
                          render={({ match }) => {
                            return <LiturgyAttendanceList match={match} />;
                          }}
                        ></Route>
                        <Route
                          path="/liturgyFullList/:liturgyGroup/"
                          render={({ match, history }) => {
                            return (
                              <Auth>
                                <LiturgyFullAttendanceList
                                  match={match}
                                  history={history}
                                />
                              </Auth>
                            );
                          }}
                        ></Route>
                        <Route
                          path="/registerstmarycovidliturgies/:liturgyid?/"
                          render={({ match, history }) => {
                            return <Register match={match} history={history} />;
                          }}
                        ></Route>
                        <Route
                          path="/joinTeams"
                          // render={({ match, history }) => {
                          //   return <PublicSite match={match} history={history} />;
                          // }}
                        >
                          <Redirect to="/zoomInstructions"></Redirect>
                        </Route>
                        <Route
                          path="/zoomInstructions"
                          render={({ match, history }) => {
                            return (
                              <ZoomInstructions
                                match={match}
                                history={history}
                              />
                            );
                          }}
                        ></Route>
                        <Route
                          path="/zoom"
                          render={() => {
                            window.location.href =
                              "https://us02web.zoom.us/j/82821174760?pwd=bUlkb3FjWFpFZFpyZmNkSEY4TU5JUT09";
                            return null;
                          }}
                        ></Route>
                        <Route
                          path="/DeaconClass/ArchangelMichael"
                          render={() => {
                            window.location.href =
                              "https://us02web.zoom.us/j/82812956188?pwd=NW16b3JGTDBSWEtLRFo0RXJXU2EvZz09";
                            return null;
                          }}
                        ></Route>
                        <Route
                          path="/DeaconClass/StHabibGirgis"
                          render={() => {
                            window.location.href =
                              "https://us02web.zoom.us/j/89238011213?pwd=L0NHZmlWeEVpZURRa21XcVRkQldxdz09";
                            return null;
                          }}
                        ></Route>
                        <Route
                          path="/DeaconClass/StPhillip"
                          render={() => {
                            window.location.href =
                              "https://us02web.zoom.us/j/85752098803?pwd=MTNrMG4yS0RtSlI1V0RBOFU1NTQrZz09";
                            return null;
                          }}
                        ></Route>
                        <Route
                          path="/"
                          render={({ match, history }) => {
                            return <Register match={match} history={history} />;
                          }}
                        ></Route>
                      </Switch>
                    </FeathersContext.Provider>
                  </div>
                </div>
              );
            }}
          ></Route>
        </Switch>
      </Router>
    );
  }
}
export default App;
