import React, { Component } from "react";
import FeathersContext from "../../feathersContext";
import {
    Button,
    Typography,
    MenuItem,
    TextField,
    Paper,
    CircularProgress,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Card from "../../components/mainComponents/Card"
import {CaserNameField,CaserSelectField,CaserLongTextField, CaserPhoneField} from "../../components/mainComponents/Fields"
import Snackbar from '@material-ui/core/Snackbar';
// function Alert(props) {
//     const [open, setOpen] = React.useState(false);
//     const handleClose = (event, reason) => {
//         // if (reason === 'clickaway') {
//         //   return;
//         // }
    
//         setOpen(false);
//       };
    
//     return  ;
//   }
  
class Dashboard extends Component {
    static contextType = FeathersContext;
    state = {
        groups: [],
        data: {},
        newGroup: "All",
        newMessage: "",
        isSending: false,
        texting_number:"",
        texting_firstname:"",
        texting_lastname:"",
        alertMessage:"",
        alertMessageType:"success"
    };
    async componentDidMount() {
        this.getData();
        this.addListeners();
    }

    getData = async () => {
        let groups = await this.context.app.service("text-groups").find({});
        this.setState({
            groups: groups.data,
        });
    };
    addListeners = () => {
        this.context.app.service("texts").on("removed", this.getData);
    };
    alert(alertMessageType="success",alertMessage="") {
        this.setState({
            alertMessage,alertMessageType
        })
    }
    sendNewText = async () => {
        this.setState({ isSending: true });
        await this.context.app.service("texts").create({
            group: this.state.newGroup,
            message: this.state.newMessage,
            sendNow: true,
        });
        this.setState({
            newMessage: "",
            isSending: false,
        });
        this.alert("success","Message Sent to "+this.state.newGroup+": "+this.state.newMessage);
    };
    handleClose = (event, reason) => {
    // if (reason === 'clickaway') {
    //   return;
    // }
    this.alert()
    };

    submitNumber = async () => {
        try {
            var standardNo = (this.state.texting_number || "").replace(
                /[^\d]/g,
                ""
            );
            if (
                !this.state.texting_firstname ||
                this.state.texting_firstname.length < 2
            ) {
                throw new Error("Please Enter the first name.");
            }
            if (
                !this.state.texting_lastname ||
                this.state.texting_lastname.length < 2
            ) {
                throw new Error("Please Enter the last name.");
            }
            if (!standardNo || standardNo.length < 10) {
                throw new Error("Incomplete Number");
            } else {
                if (standardNo.length === 10) {
                    standardNo = "1" + standardNo;
                }
                let other = await this.context.app
                    .service("text-subscribers")
                    .find({ query: { PhoneNumber: standardNo } });
                if (other.data.length) {
                    if (other.data[0].Active !== true) {
                        await this.context.app
                            .service("text-subscribers")
                            .patch(other.data[0]._id, { Active: true });
                            this.alert("success","The number has been reactivated in the database.");
                    } else {
                        this.alert("success","The number is already included in the database.");
                    }
                } else {
                    await this.context.app.service("text-subscribers").create({
                        firstName: this.state.texting_firstname,
                        middleName: this.state.texting_middlename||"",
                        lastName: this.state.texting_lastname,
                        PhoneNumber: standardNo,
                        Active: true,
                    });
                    this.alert("success","The number has been added to the database.");
                }
            }
        } catch (e) {
            console.log(e.toString())
            this.alert("error",e.toString());
        }
    };
    render() {
        return (
            <div>
            <Snackbar open={this.state.alertMessage} autoHideDuration={6000} onClose={this.handleClose}>
                <Alert onClose={this.handleClose} severity={this.state.alertMessageType||"success"}>{this.state.alertMessage}</Alert>
            </Snackbar>
                <Card
                    header="New Message"
                    footer={<Button
                        disabled={
                            this.state.newMessage || this.state.isSending
                                ? false
                                : true
                        }
                        variant={"contained"}
                        color="primary"
                        onClick={this.sendNewText}
                    >
                        {this.state.isSending ? (
                            <CircularProgress />
                        ) : (
                            "Send Message"
                        )}
                    </Button>}
                >
                        
                <CaserSelectField
                    opts={this.state.groups.map((option) => (
                        {optionId: option.name,label:option.name}
                    ))}
                    value={this.state.newGroup}
                    label="Group"
                    onChange={(newGroup) =>
                        this.setState({ newGroup })
                    }

                />
                <CaserLongTextField
                    label="Message"
                    value={this.state.newMessage}
                    onChange={(newMessage) =>
                        this.setState({ newMessage})
                    }
                    noDebounce
                    disabled={this.state.isSending}
                />
                </Card>
                <Card header="New Person"
                    footer={<Button
                        variant="contained"
                        color="primary"
                        onClick={this.submitNumber}
                        disabled={
                            !this.state.texting_firstname || !this.state.texting_lastname || this.state.texting_number.toString().length < 10
                                ? true
                                : false
                        }
                    >
                        Submit Number
                    </Button>}
                >

                        <CaserNameField
                            label={"Name"}
                            onChange={(val,node)=>this.setState({["texting_"+node]:val})}
                            value={{dbObj:{
                                firstname:this.state.texting_firstname,middlename:this.state.texting_middlename,lastname:this.state.texting_lastname,

                            }}}
                            />
                        <CaserPhoneField
                            label={"Phone Number"}
                            value={this.state.texting_number}
                            onChange={(val)=>this.setState({texting_number:val})}
                        />
                </Card>
            </div>
        )
    }
}
export default Dashboard;
