import React, { Component } from "react";
export default class OCL extends Component {
    componentDidMount() {
        document.querySelector('#my-manifest-placeholder').setAttribute('href', '/icons/manifest-ocl.json');
    }
    render() {
        return (
            <object style={{border:"none",width:"100%",height:"100%",margin:"0px",background:"#000"}} data={"https://av.saintmarydelray.com/ocl/#/HTML/3/"} />
        );
    }
}
