import React, { Component } from "react";
import Formsy from "formsy-react";
import TextInput, {
  SelectInput,
  ButtonGroupInput,
} from "../../components/formsyInput";
import axios from "axios";
import FeathersContext from "../../feathersContext";
import {
  Container,
  Button,
  // Card,
  // CardActions,
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Box,
  MenuItem,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  // Checkbox,
  Grid,
  Divider,
} from "@material-ui/core";
// import MuiAlert from "@material-ui/lab/Alert";
import moment from "moment";
import Loading from "../general/Loading";
class Signup extends Component {
  static contextType = FeathersContext;
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      step: 0,
      model: {},
      model2: {
        numberAttending: 1,
        attendee2underage: false,
        attendee3underage: false,
        attendee4underage: false,
        attendee5underage: false,
      },
      buttonDisabled: true,
      liturgies: null,
      selectedLiturgyId: null,
      data: {},
      formIsOpen: null,
      liturgiesAvailable: null,
      loadingResult: false,
    };
  }
  async componentDidMount() {
    let liturgiesAvailable = await this.context.app.service("liturgies").find({
      query: {
        liturgyDate: { $gte: moment().toISOString() },
        available: { $ne: false },
      },
    });
    let liturgiesAvailableCount = 0;
    for (let liturgy of liturgiesAvailable.data) {
      if (liturgy.countedAttending < (liturgy.maxAdults || 45))
        ++liturgiesAvailableCount;
    }
    console.log(liturgiesAvailable, liturgiesAvailableCount);

    this.setState({
      formIsOpen: await this.context.app
        .service("config")
        .get("isLiturgyFormOpen"),
      liturgiesAvailable: liturgiesAvailableCount,
    });
    console.log(this.state);
    this.checkAuthentication();
    document
      .querySelector("#my-manifest-placeholder")
      .setAttribute("href", "/icons/manifest-registration.json");
  }
  getLiturgies = async () => {
    this.setState({ liturgies: null });
    let attending = this.state.model2.numberAttending;
    console.log("attending", attending);

    if (attending > 5) attending = 5;
    if (
      this.state.model2.attendee2underage &&
      this.state.model2.numberAttending > 1
    )
      attending = attending - 1;
    if (
      this.state.model2.attendee3underage &&
      this.state.model2.numberAttending > 2
    )
      attending = attending - 1;
    if (
      this.state.model2.attendee4underage &&
      this.state.model2.numberAttending > 3
    )
      attending = attending - 1;
    if (
      this.state.model2.attendee5underage &&
      this.state.model2.numberAttending > 4
    )
      attending = attending - 1;
    console.log("attending", attending);
    let liturgies = await this.context.app.service("liturgies").find({
      query: {
        liturgyDate: { $gte: moment().toISOString() },
        available: { $ne: this.state.user ? "gooo" : false },
      },
    });
    liturgies.data = liturgies.data.map((v) => {
      v.label =
        (v.serviceType || "Liturgy") +
        " on " +
        moment(v.liturgyDate).format("dddd MMMM D YYYY, h:mm a");
      if (attending + v.countedAttending > (v.maxAdults || 45)) {
        v.disabled = true;
        if (v.countedAttending >= (v.maxAdults || 45)) v.label += " (Full)";
        else if (v.countedAttending === (v.maxAdults || 45) - 1)
          v.label += " (Only one spot left in this service)";
        else
          v.label +=
            " (Only " +
            ((v.maxAdults || 45) - v.countedAttending) +
            " spots left in this service)";
      } else {
        v.disabled = false;
        v.label += " (Available)";
      }
      return v;
    });
    console.log(liturgies.data);
    this.setState({ liturgies: liturgies.data });
  };
  async getIPInfo() {
    let resObj = {};

    try {
      let res = await axios.get("https://www.cloudflare.com/cdn-cgi/trace");
      res = res.data.split("\n");
      res = res.map((v) => v.split("="));
      console.log(res);
      for (let [id, val] of res) {
        if (val) resObj[id] = val;
      }
      console.log(resObj);
    } catch (e) {}
    return resObj;
  }
  async checkAuthentication() {
    console.log("checking auth");
    try {
      await this.context.app.reAuthenticate();
      this.setState({ user: true });
      return true;
    } catch (e) {
      this.setState({ user: false });
    }
  }
  async handleFormSubmit() {
    this.setState({ loadingResult: true });
    let model = { ...this.state.model, ...this.state.model2 };
    console.log(model);
    let data = {
      contact: { ...this.state.model },
      attendees: [
        {
          firstName: this.state.model.firstName,
          lastName: this.state.model.lastName,
          underage: false,
        },
      ],
      liturgyId: this.state.selectedLiturgyId,
      numberAttending: model.numberAttending,
      requestingMoreNotes: model.reasonForMoreThanFive,
    };

    if (model.numberAttending > 1)
      data.attendees.push({
        firstName: model.attendee2firstName,
        lastName: model.attendee2lastName,
        underage: model.attendee2underage,
      });
    if (model.numberAttending > 2)
      data.attendees.push({
        firstName: model.attendee3firstName,
        lastName: model.attendee3lastName,
        underage: model.attendee3underage,
      });
    if (model.numberAttending > 3)
      data.attendees.push({
        firstName: model.attendee4firstName,
        lastName: model.attendee4lastName,
        underage: model.attendee4underage,
      });
    if (model.numberAttending > 4)
      data.attendees.push({
        firstName: model.attendee5firstName,
        lastName: model.attendee5lastName,
        underage: model.attendee5underage,
      });

    if (model.numberAttending > 5) {
      data.requestingMore = true;
      data.numberAttending = 5;
    } else {
      data.requestingMore = false;
    }
    let attending = model.numberAttending;
    if (model.attendee2underage) attending = attending - 1;
    if (model.attendee3underage) attending = attending - 1;
    if (model.attendee4underage) attending = attending - 1;
    if (model.attendee5underage) attending = attending - 1;
    data.ofAgeAttending = attending;
    console.log(data);
    this.data = data;
    data.ip = await this.getIPInfo();
    try {
      let id = await this.context.app.service("registrations").create(data);
      this.setState({ error: null, step: 4, data: id, loadingResult: false });

      console.log(id);
    } catch (e) {
      console.log(e.message);
      this.setState({ error: e.message, step: 4, data, loadingResult: false });
    }
  }
  render() {
    return (
      <div>
        <Container
          style={{
            marginTop: 50,
            borderRadius: "10px",
            padding: 10,
            border: "solid thin #777",
          }}
        >
          <Typography variant="h5">
            Register to Attend Liturgy at St. Mary Delray Beach
          </Typography>
          {this.state.formIsOpen === null ? <Loading /> : null}
          {typeof this.state.formIsOpen === "string" ? (
            <Typography variant={"h6"} color="secondary">
              {this.state.formIsOpen}
            </Typography>
          ) : null}
          {this.state.formIsOpen === true || this.state.user ? (
            <Stepper activeStep={this.state.step} orientation="vertical">
              <Step>
                <StepLabel>
                  <Typography variant="h6">
                    Liturgy Registration Application
                  </Typography>
                </StepLabel>
                <StepContent>
                  <Typography variant="h6" color={"primary"}>
                    {!this.state.liturgiesAvailable
                      ? "There are currently no services with available spots, please check back later."
                      : this.state.liturgiesAvailable === 1
                      ? "There is one service remaining with open spots"
                      : "There are currently " +
                        this.state.liturgiesAvailable +
                        " services with open spots"}
                  </Typography>
                  {/* <Typography variant="body1">
                                    1. You can only register for one liturgy.
                                </Typography>
                                <Typography variant="body1">
                                    2. There are only 50 slots open per liturgy
                                    .
                                </Typography>
                                <Typography variant="body1">
                                    3. The first 50 people registered will be
                                    permitted inside the church for the liturgy.
                                    After which that roster for that liturgy
                                    will automatically close.
                                </Typography>
                                <Typography variant="body1">
                                    4. No one will be permitted inside the
                                    church if they are not on the list.
                                </Typography>
                                <Typography variant="body1">
                                    5. Each person can register themselves and
                                    up-to four other immediate family members
                                    (parents and children only).
                                </Typography>
                                <Typography variant="body1">
                                    6. All congregants must keep 6 feet away
                                    from each other, max 2 to a pew.
                                </Typography>
                                <Typography variant="body1">
                                    7. Front Door of Church will close at 9am
                                    Sharp the door will not be open for anyone.
                                </Typography>
                                <Typography variant="body1">
                                    8. Bring your own Lefafa or headscarf (you
                                    can choose to bring your own water).
                                </Typography>
                                <Typography variant="body1">
                                    9. Please do not touch anyone including
                                    clergy.
                                </Typography>
                                <Typography variant="body1">
                                    10. Pray for the Lord's mercy.
                                </Typography> */}
                  <br />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => this.setState({ step: 1 })}
                    disabled={
                      !this.state.liturgiesAvailable && this.state.user !== true
                    }
                  >
                    Get Started
                  </Button>
                </StepContent>
              </Step>
              <Step>
                <StepLabel>
                  <Typography variant="h6">Your Information</Typography>
                </StepLabel>
                <StepContent>
                  <Formsy
                    onValid={() =>
                      this.setState({
                        buttonDisabled: false,
                      })
                    }
                    onInvalid={() =>
                      this.setState({
                        buttonDisabled: true,
                      })
                    }
                    onChange={(model) => this.setState({ model })}
                  >
                    <Box display="flex">
                      <Box p={1} flexGrow={1}>
                        <TextInput
                          label="First Name"
                          name="firstName"
                          validations="minLength:2"
                          validationError="Please enter your first name"
                          required
                          value={this.state.model.firstName}
                        />
                      </Box>
                      <Box p={1} flexGrow={1}>
                        <TextInput
                          label="Last Name"
                          name="lastName"
                          validations="minLength:2"
                          validationError="Please enter your last name"
                          required
                          value={this.state.model.lastName}
                        />
                      </Box>
                    </Box>
                    <TextInput
                      label="Email"
                      name="email"
                      validations="isEmail"
                      validationError="This is not a valid email"
                      required
                      value={this.state.model.email}
                    />
                    <TextInput
                      label="Phone Number"
                      name="phoneNumber"
                      validations="minLength:2"
                      required
                      value={this.state.model.phoneNumber}
                    />
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => this.setState({ step: 0 })}
                    >
                      Back
                    </Button>{" "}
                    <Button
                      disabled={this.state.buttonDisabled}
                      variant="contained"
                      color="primary"
                      onClick={() => this.setState({ step: 2 })}
                    >
                      Next
                    </Button>
                  </Formsy>
                </StepContent>
              </Step>
              <Step>
                <StepLabel>
                  <Typography variant="h6">
                    Who will attend from your family?
                  </Typography>
                </StepLabel>
                <StepContent>
                  <Formsy
                    onValid={() =>
                      this.setState({
                        buttonDisabled: false,
                      })
                    }
                    onInvalid={() =>
                      this.setState({
                        buttonDisabled: true,
                      })
                    }
                    onChange={(model2) => this.setState({ model2 })}
                  >
                    <SelectInput
                      label={"How many people attending"}
                      value={this.state.model2.numberAttending || 1}
                      name={"numberAttending"}
                    >
                      <MenuItem value={1}>One Person (Just you)</MenuItem>
                      <MenuItem value={2}>Two People</MenuItem>
                      <MenuItem value={3}>Three People</MenuItem>
                      <MenuItem value={4}>Four People</MenuItem>
                      <MenuItem value={5}>Five People</MenuItem>
                      <MenuItem value={6}>More than Five People</MenuItem>
                    </SelectInput>
                    <ol>
                      <li>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <TextInput
                              label="First Name"
                              name="attendee1firstName"
                              value={this.state.model.firstName}
                              readOnly
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextInput
                              label="Last Name"
                              name="attendee1lastName"
                              value={this.state.model.lastName}
                              readOnly
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Divider />
                      </li>
                      {this.state.model2.numberAttending > 1 ? (
                        <li>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={4}>
                              <TextInput
                                label="First Name"
                                name="attendee2firstName"
                                required
                                value={this.state.model2.attendee2firstName}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                              <TextInput
                                label="Last Name"
                                name="attendee2lastName"
                                required
                                value={this.state.model2.attendee2lastName}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                              <ButtonGroupInput
                                options={[
                                  {
                                    label: "Over 6 Years old",
                                    value: false,
                                  },
                                  {
                                    label: "6 or Under",
                                    value: true,
                                  },
                                ]}
                                name="attendee2underage"
                                value={
                                  this.state.model2.attendee2underage || false
                                }
                              />
                            </Grid>
                          </Grid>
                          <Divider />
                        </li>
                      ) : null}
                      {this.state.model2.numberAttending > 2 ? (
                        <li>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={4}>
                              <TextInput
                                label="First Name"
                                name="attendee3firstName"
                                required
                                value={this.state.model2.attendee3firstName}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                              <TextInput
                                label="Last Name"
                                name="attendee3lastName"
                                required
                                value={this.state.model2.attendee3lastName}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                              <ButtonGroupInput
                                options={[
                                  {
                                    label: "Over 6 Years old",
                                    value: false,
                                  },
                                  {
                                    label: "6 or Under",
                                    value: true,
                                  },
                                ]}
                                name="attendee3underage"
                                value={
                                  this.state.model2.attendee3underage || false
                                }
                              />
                            </Grid>
                          </Grid>
                          <Divider />
                        </li>
                      ) : null}
                      {this.state.model2.numberAttending > 3 ? (
                        <li>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={4}>
                              <TextInput
                                label="First Name"
                                name="attendee4firstName"
                                required
                                value={this.state.model2.attendee4firstName}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                              <TextInput
                                label="Last Name"
                                name="attendee4lastName"
                                required
                                value={this.state.model2.attendee4lastName}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                              <ButtonGroupInput
                                options={[
                                  {
                                    label: "Over 6 Years old",
                                    value: false,
                                  },
                                  {
                                    label: "6 or Under",
                                    value: true,
                                  },
                                ]}
                                name="attendee4underage"
                                value={
                                  this.state.model2.attendee4underage || false
                                }
                              />
                            </Grid>
                          </Grid>
                          <Divider />
                        </li>
                      ) : null}
                      {this.state.model2.numberAttending > 4 ? (
                        <li>
                          {" "}
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={4}>
                              <TextInput
                                label="First Name"
                                name="attendee5firstName"
                                required
                                value={this.state.model2.attendee5firstName}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                              <TextInput
                                label="Last Name"
                                name="attendee5lastName"
                                required
                                value={this.state.model2.attendee5lastName}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                              <ButtonGroupInput
                                value={
                                  this.state.model2.attendee5underage || false
                                }
                                options={[
                                  {
                                    label: "Over 6 Years old",
                                    value: false,
                                  },
                                  {
                                    label: "6 or Under",
                                    value: true,
                                  },
                                ]}
                                name="attendee5underage"
                              />
                            </Grid>
                          </Grid>
                          <Divider />
                        </li>
                      ) : null}
                    </ol>
                    {this.state.model2.numberAttending > 5 ? (
                      <Box>
                        <Typography variant="body1">
                          To accommodate everyone, there is a limit of 5 people
                          per family registration. If there is a reason that you
                          would need to register more than that, please state
                          who else you would like to register and the reason for
                          the accommodation, and the reason will be reviewed
                          (this is not a guarantee):
                        </Typography>
                        <TextInput
                          name="reasonForMoreThanFive"
                          multiline
                          required
                          label="Enter your reasoning below"
                          value={this.state.model2.reasonForMoreThanFive}
                        />
                      </Box>
                    ) : null}
                    <br />
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => this.setState({ step: 1 })}
                    >
                      Back
                    </Button>{" "}
                    <Button
                      disabled={this.state.buttonDisabled}
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        this.setState({ step: 3 });
                        this.getLiturgies();
                      }}
                    >
                      Next
                    </Button>
                  </Formsy>
                  {/* <button disabled={!this.state.canSubmit}>Submit</button> */}
                </StepContent>
              </Step>
              <Step>
                <StepLabel>
                  <Typography variant="h6">Choose Service</Typography>
                </StepLabel>
                <StepContent>
                  <Box>
                    {this.state.liturgies === null ? (
                      <Typography variant="h6">
                        Loading Services, please wait...
                      </Typography>
                    ) : (
                      <>
                        <Box>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              this.getLiturgies();
                            }}
                          >
                            Refresh Availablity
                          </Button>
                          <br />
                          <br />
                        </Box>
                        <FormControl component="fieldset">
                          <FormLabel component="legend">
                            Select Service
                          </FormLabel>
                          <RadioGroup
                            defaultValue={this.state.selectedLiturgyId}
                            name="liturgyId"
                            onChange={(v) =>
                              this.setState({
                                selectedLiturgyId: v.target.value,
                              })
                            }
                          >
                            {this.state.liturgies.map((v, i) => (
                              <FormControlLabel
                                value={v._id}
                                control={<Radio />}
                                label={v.label}
                                disabled={
                                  v.disabled && this.state.user !== true
                                }
                              />
                            ))}
                          </RadioGroup>
                        </FormControl>
                      </>
                    )}
                  </Box>
                  {this.state.loadingResult ? (
                    <Typography variant="h6">Please wait...</Typography>
                  ) : (
                    <>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => this.setState({ step: 2 })}
                      >
                        Back
                      </Button>{" "}
                      <Button
                        onClick={this.handleFormSubmit.bind(this)}
                        variant="contained"
                        color="primary"
                        type={"submit"}
                        disabled={this.state.selectedLiturgyId === null}
                      >
                        Submit
                      </Button>
                    </>
                  )}
                </StepContent>
              </Step>
              <Step>
                <StepLabel>
                  <Typography variant="h6">Confirmation</Typography>
                </StepLabel>
                <StepContent>
                  {this.state.error === null ? (
                    <Typography variant="body1">
                      You have successfully applied to attend the selected
                      service.{" "}
                      {Array.isArray(this.state.data.flaggedForIP)
                        ? "However, your application was flagged for possible duplication. It will be reviewed manually by a team member soon. "
                        : ""}
                      You will receieve an email shortly to confirm your
                      registration. If you do not receive the email within 24
                      hours, please contact our registration team.
                    </Typography>
                  ) : (
                    <Box>
                      <Typography variant="body1" style={{ color: "red" }}>
                        An error has occured: {this.state.error}
                      </Typography>
                      {this.state.user ? (
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => {
                            this.setState({ step: 3 });
                            this.getLiturgies();
                          }}
                        >
                          Back
                        </Button>
                      ) : null}
                    </Box>
                  )}
                </StepContent>
              </Step>
            </Stepper>
          ) : null}
        </Container>
      </div>
    );
  }
}

export default Signup;
