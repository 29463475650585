import React, { Component } from "react";
import FeathersContext from "../../feathersContext";
import { debounce } from "throttle-debounce";
import Loading from "../general/Loading";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import {
  RadioGroup,
  FormControl,
  ButtonGroup,
  Button,
  Typography,
  Card,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Chip,
  Box,
  TextField,
  Select,
  MenuItem,
  Divider,
  InputLabel,
  Tabs,
  Tab,
  AppBar,
  Toolbar,
  InputBase,
  CardActions,
} from "@material-ui/core";
import { fade, withStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

function TabPanel(props) {
  const { children, value, index } = props;
  if (value === index) {
    return <div>{children}</div>;
  }
  return null;
}

class Dashboard extends Component {
  static contextType = FeathersContext;
  state = {
    ...this.props.match.params,
    tab: 0,
  };
  async componentDidMount() {}

  render() {
    return (
      <div
        style={{
          display: "flex",
          flexGrow: 0,
          flexDirection: "column",
        }}
      >
        {/* <Tabs
          value={this.state.tab}
          onChange={(e, tab) => this.setState({ tab })}
        >
          <Tab label="Voicemails" id={0} />
        </Tabs>
        <TabPanel index={0} value={this.state.tab}> */}
        <Voicemails {...this.props} />
        {/* </TabPanel> */}
      </div>
    );
  }
}
export default Dashboard;

class Voicemails extends Component {
  static contextType = FeathersContext;
  state = {
    ...this.props.match.params,
    confirmingUsers: [],
    voicemails: [],
  };
  async componentDidMount() {
    this.getData();
    this.addListeners();
  }

  getData = async () => {
    await this.getVoicemails();
    let confirmingUsers = (
      await this.context.app
        .service("users")
        .find({ query: { confirmer: true } })
    ).data;
    this.setState({
      confirmingUsers,
    });
  };

  getVoicemails = async () => {
    let voicemails = await this.context.app
      .service("election-voicemails")
      .find({});
    this.setState({
      voicemails: voicemails.data.sort(
        (a, b) =>
          moment(b.callDateTime).toDate() - moment(a.callDateTime).toDate()
      ),
    });
  };

  addListeners = () => {
    this.context.app
      .service("election-voicemails")
      .on("created", this.getVoicemails);
    this.context.app
      .service("election-voicemails")
      .on("patched", this.getVoicemails);
    this.context.app
      .service("election-voicemails")
      .on("removed", this.getVoicemails);
  };
  displayPhoneNumber(number = "") {
    let onlyNumber = number.replace(/\D/g, "");
    if (onlyNumber.length < 10) return number;
    if (onlyNumber.length > 11) return number;
    if (onlyNumber.length === 10) onlyNumber = "1" + onlyNumber;
    return (
      "(" +
      onlyNumber[1] +
      onlyNumber[2] +
      onlyNumber[3] +
      ") " +
      onlyNumber.substring(4, 7) +
      "-" +
      onlyNumber.substring(7)
    );
  }
  render() {
    return (
      <div
        style={{
          display: "flex",
          flexGrow: 0,
          flexDirection: "column",
        }}
      >
        <Typography
          style={{ flexGrow: 1 }}
          variant="h5"
          color="primary"
          gutterBottom
        >
          Voicemails
        </Typography>
        {this.state.voicemails
          .filter((vm) => moment(vm.callDateTime).isAfter(moment("2021-03-01")))
          .map((vm, vmi) => (
            <ExpansionPanel
              key={vm._id}
              TransitionProps={{ unmountOnExit: true }}
            >
              <ExpansionPanelSummary>
                {vm.Direction === "Incoming" &&
                vm.addressed !== "Addressed" &&
                vm.addressed !== "No Answer" ? (
                  <Chip
                    style={{ marginRight: 10 }}
                    color={"secondary"}
                    label={"Needs Attention"}
                  />
                ) : null}
                {vm.Direction === "Incoming" && vm.addressed === "No Answer" ? (
                  <Chip
                    color={"secondary"}
                    label={"No Answer"}
                    style={{ marginRight: 10 }}
                  />
                ) : null}
                {vm.Direction === "Incoming" && vm.addressed === "Addressed" ? (
                  <Chip
                    color={"Primary"}
                    label={"Addressed"}
                    style={{ marginRight: 10 }}
                  />
                ) : null}
                <Typography variant={"h6"}>
                  {vm.firstName ? vm.firstName + " " : ""}
                  {vm.lastName ? vm.lastName + " " : ""}
                  {this.displayPhoneNumber(vm.from)}
                </Typography>
                <Box flexGrow={1} />
                <Typography variant={"h6"}></Typography>
                {vm.Direction} - {moment(vm.callDateTime).fromNow()}
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Box width="100%">
                  <Box
                    display="flex"
                    padding="5px"
                    width="inherit"
                    alignContent="center"
                    alignItems="center"
                  >
                    <Box flexGrow="1">
                      <CaserTextField
                        label="First Name"
                        value={vm.firstName}
                        onChange={(v) => {
                          let newText = v;
                          let vmsObj = this.state.voicemails;
                          vmsObj[vmi].firstName = newText;
                          this.setState({
                            voicemails: vmsObj,
                          });
                          this.context.app
                            .service("election-voicemails")
                            .patch(vm._id, {
                              firstName: newText,
                            });
                        }}
                      />
                    </Box>{" "}
                    <Box flexGrow="1">
                      <CaserTextField
                        label="Last Name"
                        value={vm.lastName}
                        onChange={(v) => {
                          let newText = v;
                          let vmsObj = this.state.voicemails;
                          vmsObj[vmi].lastName = newText;
                          this.setState({
                            voicemails: vmsObj,
                          });
                          this.context.app
                            .service("election-voicemails")
                            .patch(vm._id, {
                              lastName: newText,
                            });
                        }}
                      />
                    </Box>
                  </Box>
                  <AudioPlayer
                    style={{ width: "inherit" }}
                    src={vm.vm}
                    onPlay={(e) => console.log("onPlay")}
                    // other props here
                  />
                  {vm.Direction === "Incoming" ? (
                    <ButtonGroup>
                      <Button
                        color={
                          vm.addressed === "Addressed" ? "primary" : "default"
                        }
                        variant="contained"
                        size="small"
                        onClick={() => {
                          let val = "Addressed";
                          if (vm.addressed === "Addressed") val = null;
                          let vmsObj = this.state.voicemails;
                          vmsObj[vmi].addressed = val;
                          this.setState({
                            voicemails: vmsObj,
                          });
                          this.context.app
                            .service("election-voicemails")
                            .patch(vm._id, {
                              addressed: val,
                            });
                        }}
                      >
                        Addressed
                      </Button>
                      <Button
                        color={
                          vm.addressed === "Not Addressed"
                            ? "primary"
                            : "default"
                        }
                        variant="contained"
                        size="small"
                        onClick={() => {
                          let val = "Not Addressed";
                          if (vm.addressed === "Not Addressed") val = null;
                          let vmsObj = this.state.voicemails;
                          vmsObj[vmi].addressed = val;
                          this.setState({
                            voicemails: vmsObj,
                          });
                          this.context.app
                            .service("election-voicemails")
                            .patch(vm._id, {
                              addressed: val,
                            });
                        }}
                      >
                        Not Addressed
                      </Button>
                      <Button
                        color={
                          vm.addressed === "No Answer" ? "primary" : "default"
                        }
                        variant="contained"
                        size="small"
                        onClick={() => {
                          let val = "No Answer";
                          if (vm.addressed === "No Answer") val = null;
                          let vmsObj = this.state.voicemails;
                          vmsObj[vmi].addressed = val;
                          this.setState({
                            voicemails: vmsObj,
                          });
                          this.context.app
                            .service("election-voicemails")
                            .patch(vm._id, {
                              addressed: val,
                            });
                        }}
                      >
                        No Answer
                      </Button>
                    </ButtonGroup>
                  ) : null}
                  <br />
                  <br />
                  <CaserTextField
                    label="Call Notes"
                    multiline
                    value={vm.notes}
                    onChange={(v) => {
                      let newText = v;
                      let vmsObj = this.state.voicemails;
                      vmsObj[vmi].notes = newText;
                      this.setState({ voicemails: vmsObj });
                      this.context.app
                        .service("election-voicemails")
                        .patch(vm._id, {
                          notes: newText,
                        });
                    }}
                  />
                </Box>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ))}
      </div>
    );
  }
}

class CaserTextField extends React.Component {
  checkEditable() {
    if (this.props.readonly || this.props.disabled) return false;
    return true;
  }
  sendDebounceUpdate(val, node) {
    debounce(1000, (val) => {
      this.props.onChange(val, node);
    });
  }
  sendDirectUpdate(val, node) {
    this.props.onChange(val, node);
  }
  state = { value: this.props.value, newVal: null, focused: false };
  sendUpdate = debounce(1000, (val) => {
    this.props.onChange(val);
  });

  async onBlur() {
    await this.props.onChange(this.state.value);
    if (this.state.newVal !== null) {
      this.setState({
        value: this.state.newVal,
        newVal: null,
        focused: false,
      });
    } else if (this.state.focused) {
      this.setState({ focused: false });
    }
  }
  onFocus() {
    if (!this.state.focused) {
      this.setState({ focused: true });
    }
  }
  componentDidUpdate() {
    this.updateValue();
  }
  updateValue() {
    if (this.state.focused === true) {
      if (this.state.newVal === this.props.value) {
        return;
      }
      this.setState({ newVal: this.props.value });
    } else {
      if (this.state.value === this.props.value) {
        return;
      }
      this.setState({ value: this.props.value });
    }
  }
  onChange(event) {
    if (!this.checkEditable()) return;
    let value = event.target.value;
    this.setState({
      value,
    });
    this.sendUpdate(value);
  }
  render() {
    return (
      <TextField
        label={this.props.label}
        value={this.state.value || ""}
        onChange={this.onChange.bind(this)}
        margin="dense"
        variant="outlined"
        InputProps={{ readOnly: this.props.readonly }}
        disabled={this.props.disabled}
        fullWidth
        multiline={this.props.multiline || false}
        onFocus={this.onFocus.bind(this)}
        onBlur={this.onBlur.bind(this)}
      />
    );
  }
}
