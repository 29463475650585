import React, { Component } from "react";
import Formsy from "formsy-react";
import TextInput from "../../components/formsyInput";

import FeathersContext from "../../feathersContext";
import {
    Container,
    Button,
    Card,
    CardActions,
    Typography,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
class Signup extends Component {
    static contextType = FeathersContext;
    constructor(props) {
        super(props);
        this.state = {
            error: null,
        };
    }
    async handleFormSubmit(model) {
        console.log(model);
        delete model.repeat_password;
        try {
            let id = await this.context.app.service("users").create(model);
            console.log(id);
            await this.context.app.authenticate({
                strategy: "local",
                email: model.email,
                password: model.password,
            });
            window.location = "/admin";
        } catch (e) {
            console.log(e.message);
            this.setState({ error: e.message });
        }
    }

    render() {
        return (
            <div>
                <Container maxWidth={"sm"} style={{ marginTop: 150 }}>
                    <Typography variant="h5" gutterBottom>
                        Admin Signup
                    </Typography>
                    <Card raised style={{ padding: 60 }}>
                        {this.state.error ? (
                            <MuiAlert severity="error">
                                {this.state.error}
                            </MuiAlert>
                        ) : null}
                        <Formsy
                            onValidSubmit={this.handleFormSubmit.bind(this)}
                            onValid={this.enableButton}
                            onInvalid={this.disableButton}
                        >
                            <TextInput
                                label="Email"
                                name="email"
                                validations="isEmail"
                                validationError="This is not a valid email"
                                required
                            />
                            <TextInput
                                label="First Name"
                                name="firstName"
                                validations="minLength:2"
                                validationError="Please enter your first name"
                                required
                            />
                            <TextInput
                                label="Last Name"
                                name="lastName"
                                validations="minLength:2"
                                validationError="Please enter the name of the firm"
                                required
                            />
                            <TextInput
                                label="Password"
                                name="password"
                                type="password"
                                required
                            />
                            <TextInput
                                label="Repeat Password"
                                name="repeat_password"
                                type="password"
                                validations="equalsField:password"
                                validationError="Does not match password"
                                required
                            />
                            <br />
                            <br />
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                // onClick={event => this.handleLoginClick(event)}
                            >
                                Create Account
                            </Button>
                            {/* <button disabled={!this.state.canSubmit}>Submit</button> */}
                        </Formsy>
                        {/* <TextField
              hintText="Enter your Email"
              fullWidth
              label="Email"
              onChange={event =>
                this.setState({ username: event.target.value })
              }
            />
            <br />
            <TextField
              type="password"
              fullWidth
              hintText="Enter your Password"
              label="Password"
              onChange={event =>
                this.setState({ password: event.target.value })
              }
              onKeyDown={e => {
                if (e.keyCode === 13) {
                  this.handleLoginClick();
                }
              }}
            />{" "}
            <br />
            <br />
            <Button
              variant="contained"
              color="primary"
              onClick={event => this.handleLoginClick(event)}
            >
              Submit
            </Button> */}
                        <CardActions></CardActions>
                    </Card>
                </Container>
            </div>
        );
    }
}

export default Signup;
