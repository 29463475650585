import React from 'react'
import { Icon } from 'antd';

export default class Button extends React.Component {
    state = {
        isConfirming: false,
        timeLeft: 0
    }
    _ismounted = false;
    componentDidMount() {
        this._ismounted = true;
    }

    componentWillUnmount() {
        this._ismounted = false;
    }
    click() {
        if (this.state.isConfirming === true) {
            this.props.onClick();
        }
        else {
            this.setState({ timeLeft: 3, isConfirming: true });
            var self = this;
            const interval = () => {
                if (self._ismounted === true) {
                    if (self.state.timeLeft > 1) {
                        self.setState({ timeLeft: self.state.timeLeft - 1 });
                        setTimeout(interval, 1000);
                    }
                    else {
                        this.setState({ timeLeft: 0, isConfirming: false });
                    }
                }
            }
            setTimeout(interval, 1000);
        }
    }
    render() {
        return (
            <button
                shape={this.props.shape || "dafault"}
                disabled={this.props.disabled || false}
                noborder={(this.props.noBorder || false).toString()}
                className={"caser-button"} type={this.props.type || "default"}
                onClick={this.props.onClick}
            >
                {this.props.icon ?
                    <>
                        <Icon type={this.props.icon} />
                        {' '}
                    </>
                    : null}
                {this.props.children}
            </button>
        )
    }
}